//////////////////////// DEPENDENCIES ////////////////////////

import React from 'react';
import {
  makeStyles,
  useTheme,
  Grid,
  Collapse,
  Typography,
  TextField,
  InputAdornment,
  Icon,
  MenuItem,
  Tooltip,
  IconButton,
  Divider,
  Tab,
  Tabs,
  AppBar,
} from '@material-ui/core';
import { Button, Spacer } from '@hopdrive/storybook';

import ManualIcon from '@material-ui/icons/NatureTwoTone';
import { ContextMenu, MenuItem as ContextMenuItem, ContextMenuTrigger } from 'react-contextmenu';
import { useTools } from '../../../hooks/useTools';
import { useSettings } from '../providers/SettingsProvider';
import { useRegions } from '../providers/RegionsProvider';
import { useUnassignedMoves } from '../providers/UnassignedMovesProvider';
import { useScheduler } from '../providers/SchedulerProvider';
import { DefaultEmptyFallback } from '../../../components/Fallbacks';
import CustomerMultiselect from '../../../components/CustomerMultiselect/CustomerMultiselect';
import ReusableMultiselect from '../../../components/ReusableMultiselect/ReusableMultiselect';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import { MoveTileHangtag } from '../index';
import DeadlineChip from '../../../components/MoveComponents/DeadlineChip';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
dayjs.extend(isBetween);

const getDefaultShowFilters = (override = false) => {
  const localShowFilters = localStorage.getItem(`unassigned-moves-list-show-filters`);
  if (localShowFilters && !override) return localShowFilters === `true` ? true : false;
  return true;
};
const getDefaultFilterType = (override = false) => {
  const localFilterType = localStorage.getItem(`unassigned-moves-list-filter-type`);
  if (localFilterType && !override) return localFilterType;
  return `any`;
};
const getDefaultDate = (override = false) => {
  const localDate = localStorage.getItem(`unassigned-moves-list-date`);
  if (localDate && !override) return localDate;
  return `timeline date`;
};
const getDefaultMoveType = (override = false) => {
  const localMoveType = localStorage.getItem(`unassigned-moves-list-move-type`);
  if (localMoveType && !override) return localMoveType;
  return `any`;
};
const getDefaultTimeLeft = (override = false) => {
  const localTimeLeft = localStorage.getItem(`unassigned-moves-list-time-left`);
  if (localTimeLeft && !override) return localTimeLeft;
  return `any`;
};
const getDefaultCustomerIdArray = (override = false) => {
  const localCustomerIds = localStorage.getItem(`unassigned-moves-list-customer-id-array`);
  const localCustomerIdArray = localCustomerIds ? localCustomerIds.split(',') : null;
  if (Array.isArray(localCustomerIdArray) && localCustomerIdArray.length && !override)
    return localCustomerIdArray.map(customerId => parseInt(customerId));
  return [];
};
const getDefaultSelectedRegionIds = (override = false) => {
  const localRegionIds = localStorage.getItem(`unassigned-moves-list-region-id-array`);
  const localRegionIdArray = localRegionIds ? localRegionIds.split(',') : null;
  if (Array.isArray(localRegionIdArray) && localRegionIdArray.length && !override)
    return localRegionIdArray.map(regionId => parseInt(regionId));
  return [];
};
const getDefaultSearch = (override = false) => {
  const localSearch = localStorage.getItem(`unassigned-moves-list-search`);
  if (localSearch && !override) return localSearch;
  return ``;
};

//////////////////////// COMPONENT ////////////////////////

export default function UnassignedMovesList(props) {
  const theme = useTheme();
  const cls = useStyles();

  const {
    goToMoveDetails,
    goToLaneDetails,
    goToPlansGroup,
    capFirst,
    getFormattedVehicleFromMove,
    getRegionIdFromMove,
    getDriveTypeFromMove,
    getMoveDeadline,
    getCleansedPhoneNumber,
    getDurationInMinutes,
    searchLocationOnGoogleMaps,
    searchLaneOnGoogleMaps,
  } = useTools();
  const { timelineDate } = useSettings();
  const { timezoneOverride, regions } = useRegions();
  const { unassignedMoves } = useUnassignedMoves();
  const { workingPlan, isSchedulerVisible, checkMoveAssign, tryToPlanMove } = useScheduler();

  const [filteredMoves, setFilteredMoves] = React.useState([]);
  const [groupableMoveIdsSet, setGroupableMoveIdsSet] = React.useState(new Set());
  const [disabledMoveIds, setDisabledMoveIds] = React.useState([]);

  const [showFilters, setShowFilters] = React.useState(getDefaultShowFilters());
  const [filterType, setFilterType] = React.useState(getDefaultFilterType());
  const [date, setDate] = React.useState(getDefaultDate());
  const [moveType, setMoveType] = React.useState(getDefaultMoveType());
  const [timeLeft, setTimeLeft] = React.useState(getDefaultTimeLeft());
  const [customerIdArray, setCustomerIdArray] = React.useState(getDefaultCustomerIdArray());
  const [search, setSearch] = React.useState(props.search || getDefaultSearch());
  const [selectedRegionIds, setSelectedRegionIds] = React.useState(getDefaultSelectedRegionIds());
  const [inPlanner, setInPlanner] = React.useState(false);

  // If a plan is selected, pre-select the driver's region ID
  React.useEffect(() => {
    if (props?.selectedPlan?.plan?.driver?.region_id) {
      setSelectedRegionIds([props.selectedPlan.plan.driver.region_id]);
      setInPlanner(true);
    }
  }, [props]);

  // When we get our initial unassigned moves, build an array of move ids that can be grouped
  React.useEffect(() => {
    if (!unassignedMoves?.length) {
      setGroupableMoveIdsSet(new Set()) // Clear groupable move ids if no unassigned moves
      return;
    }
  
    // Use filter to find moves with groupable moves and map to get their ids
    const groupableArr = unassignedMoves.filter(move => move.groupable_moves?.length > 0).map(move => move.id);
    setGroupableMoveIdsSet(new Set(groupableArr));
  }, [unassignedMoves]);

  React.useEffect(() => {
    let filteredArr = [...unassignedMoves];
    let disabledArr = [];

    if (filteredArr.length > 0) {
      filteredArr = filteredArr.filter(m => {
        const readyByDate = dayjs(m.ready_by);
        if (filterType === `groupable` && !groupableMoveIdsSet?.has(m.id)) return false;
        if (filterType === `manual` && !m.manual_flag) return false;
        if (filterType === `paired` && !(m.parent_move_id > 0)) return false;
  
        if (date === `timeline date` && !readyByDate.isSame(timelineDate.format(), `day`)) return false;
        if (date === `today` && !readyByDate.isSame(dayjs().format(), `day`)) return false;
        if (date === `yesterday` && !readyByDate.add(1, `day`).isSame(dayjs().format(), `day`)) return false;
        if (date === `tomorrow` && !readyByDate.subtract(1, `day`).isSame(dayjs().format(), `day`)) return false;
        if (date === 'past 7 days' && !dayjs(m.ready_by).isBetween(dayjs().subtract(7, 'day'), dayjs(), 'day', '[]')) return false;
        if (date === 'past 60 days' && !dayjs(m.ready_by).isBetween(dayjs().subtract(60, 'day'), dayjs(), 'day', '[]')) return false;
        if (date === 'next 7 days' && !dayjs(m.ready_by).isBetween(dayjs(), dayjs().add(7, 'day'), 'day', '[]')) return false;
        if (date === 'next 60 days' && !dayjs(m.ready_by).isBetween(dayjs(), dayjs().add(60, 'day'), 'day', '[]')) return false;
        if (date === 'future' && !dayjs(m.ready_by).isAfter(dayjs(), 'day')) return false;
        if (date === 'past' && !dayjs(m.ready_by).isBefore(dayjs(), 'day')) return false;
        if (date === 'month' && !dayjs(m.ready_by).isSame(dayjs(), 'month')) return false;
  
        if (moveType === `concierge` && !(m.consumer_type === `customer` || m.consumer_type === `loaner`)) return false;
        if (moveType === `ops` && (m.consumer_type === `customer` || m.consumer_type === `loaner`)) return false;

        if (!isNaN(timeLeft) && timeLeft !== `any`) {
          const refTime = dayjs().add(m?.lane?.duration_sec, `seconds`).add(15, `minutes`).format();
          const deadlineMs = getMoveDeadline(m?.deliver_by, refTime);
          const timeLeftMs = parseInt(timeLeft) * 60 * 60 * 1000;
          if (deadlineMs === null || deadlineMs > timeLeftMs) return false;
        }

        if (customerIdArray?.length > 0 && !customerIdArray.includes(m.customer.id)) return false;
        if (selectedRegionIds?.length > 0 && !selectedRegionIds.includes(getRegionIdFromMove(m))) return false;

        return true;
      });

      if (search && search.length > 0) {
        const searchLower = search.toLocaleLowerCase();
        filteredArr = filteredArr.filter(
          m =>
            `${m.id}`.includes(searchLower) ||
            m.ready_by?.toLowerCase().includes(searchLower) ||
            m.pickup_time?.toLowerCase().includes(searchLower) ||
            m.lane.description?.toLowerCase().includes(searchLower) ||
            getFormattedVehicleFromMove(m).toLowerCase().includes(searchLower) ||
            m.consumer_name?.toLowerCase().includes(searchLower) ||
            m.customer.name?.toLowerCase().includes(searchLower) ||
            m.vehicle_vin?.toLowerCase().includes(searchLower)
        );
      }

      // Check schedule filter
      if (workingPlan && isSchedulerVisible) {
        const moveIdArray = workingPlan.enrichedMoves.map(enrichedMove => enrichedMove.move.id);
        filteredArr = filteredArr.filter(m => !moveIdArray.includes(m.id));
        disabledArr = filteredArr.filter(m => !checkMoveAssign(workingPlan.plan.driver_id, m)).map(m => m.id);
      }

      // Sort moves based on deliver_by and ready_by
      filteredArr = filteredArr.sort((a, b) => {
        const aDeliverTime = a.deliver_by;
        const bDeliverTime = b.deliver_by;
        const aReadyTime = a.ready_by;
        const bReadyTime = b.ready_by;
        if (!aDeliverTime && bDeliverTime) return 1;
        if (aDeliverTime && !bDeliverTime) return -1;
        if (aDeliverTime < bDeliverTime) return -1;
        if (aDeliverTime > bDeliverTime) return 1;
        if (!aReadyTime && bReadyTime) return 1;
        if (aReadyTime && !bReadyTime) return -1;
        return aReadyTime < bReadyTime ? -1 : aReadyTime > bReadyTime ? 1 : 0;
      });
    }

    setFilteredMoves(filteredArr);
    setDisabledMoveIds(disabledArr);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    groupableMoveIdsSet,
    filterType,
    customerIdArray,
    selectedRegionIds,
    unassignedMoves,
    workingPlan,
    isSchedulerVisible,
    date,
    moveType,
    timeLeft,
    search,
    timelineDate,
  ]);

  // Controls show filters
  const handleShowFilters = () => {
    setShowFilters(!showFilters);
    localStorage.setItem(`unassigned-moves-list-show-filters`, !showFilters);
  };

  // Controls filter type
  const handleFilterTypeChange = () => {
    let newFilterType = `any`;

    if (filterType === `any`) {
      newFilterType = `groupable`;
    } else if (filterType === `groupable`) {
      newFilterType = `manual`;
    } else if (filterType === `manual`) {
      newFilterType = `paired`;
    } else {
      newFilterType = `any`;
    }

    localStorage.setItem(`unassigned-moves-list-filter-type`, newFilterType);
    setFilterType(newFilterType);
  };

  // Controls filter input
  const handleInput = name => event => {
    const value = event.target.value;
    if (name === `date`) {
      setDate(value);
      localStorage.setItem(`unassigned-moves-list-date`, value);
    }
    if (name === `timeLeft`) {
      setTimeLeft(value);
      localStorage.setItem(`unassigned-moves-list-time-left`, value);
    }
  };

  const handleMoveTypeChange = newValue => {
    setMoveType(newValue);
    localStorage.setItem('unassigned-moves-list-move-type', newValue);
  };

  const handleChangeCustomers = value => {
    setCustomerIdArray(value);
    localStorage.setItem(`unassigned-moves-list-customer-id-array`, value);
  };

  const handleChangeRegions = value => {
    let regionIds = [];
    if (Array.isArray(value) && value.length > 0) {
      regionIds = value.map(region => region.id);
    }
    setSelectedRegionIds(regionIds);
    //If we're in the planner, the region selections are applicable to that driver,
    //so we won't save them to local storage, where the unassigned moves list on the global plans screen reads them. 
    if (!inPlanner) {
    localStorage.setItem(`unassigned-moves-list-region-id-array`, regionIds);
    }
  };

  // Controls search input
  const handleSearchInput = event => {
    setSearch(event.target.value.toLocaleLowerCase());
  };

  const checkIfFilters = () => {
    return (
      filterType !== `any` ||
      date !== `timeline date` ||
      moveType !== `any` ||
      timeLeft !== `any` ||
      customerIdArray.length > 0 ||
      selectedRegionIds.length > 0 ||
      search.length > 0
    );
  };

  // Clear out all the filters and set localStorage
  const handleClearFilters = () => {
    setShowFilters(getDefaultShowFilters(true));
    setFilterType(getDefaultFilterType(true));
    setDate(getDefaultDate(true));
    setMoveType(getDefaultMoveType(true));
    setTimeLeft(getDefaultTimeLeft(true));
    setCustomerIdArray(getDefaultCustomerIdArray(true));
    setSelectedRegionIds(getDefaultSelectedRegionIds(true));
    setSearch(getDefaultSearch(true));

    localStorage.setItem(`unassigned-moves-list-show-filters`, getDefaultShowFilters(true));
    localStorage.setItem(`unassigned-moves-list-filter-type`, getDefaultFilterType(true));
    localStorage.setItem(`unassigned-moves-list-date`, getDefaultDate(true));
    localStorage.setItem(`unassigned-moves-list-move-type`, getDefaultMoveType(true));
    localStorage.setItem(`unassigned-moves-list-time-left`, getDefaultTimeLeft(true));
    localStorage.setItem(`unassigned-moves-list-customer-id-array`, getDefaultCustomerIdArray(true));
    localStorage.setItem(`unassigned-moves-list-region-id-array`, getDefaultSelectedRegionIds(true));
    localStorage.setItem(`unassigned-moves-list-search`, getDefaultSearch(true));
  };

  const getFilterTypeIcon = () => {
    if (filterType === `groupable`) return <Icon className={cls.filterBtnIcon}>hub</Icon>;
    if (filterType === `manual`) return <ManualIcon className={cls.filterBtnIcon} />;
    if (filterType === `paired`) return <Icon className={cls.filterBtnIcon}>link</Icon>;
    return <Icon className={cls.filterBtnIcon}>all_inclusive</Icon>;
  };

  const getFilterTypeText = () => {
    if (filterType === `groupable`) return `Showing Groupable Moves`;
    if (filterType === `manual`) return `Showing Manual Moves`;
    if (filterType === `paired`) return `Showing Paired Moves`;
    return `Showing All Moves`;
  };

  const unassignedMoveActions = [
    {
      label: `Go To Move Details`,
      handler: move => goToMoveDetails(move.id, false, true),
    },
    {
      label: `Go To Lane Details`,
      handler: move => goToLaneDetails(move.lane ? move.lane.id : null),
    },
    // {
    //   label: `Plan Move`,
    //   handler: move => tryToPlanMove(move, undefined, groupableMoveIds?.includes(move.id)),
    // },
    {
      label: `Plan Group`,
      handler: move =>
        goToPlansGroup({
          date: dayjs(move?.ready_by).format(`YYYY-MM-DD`),
          moveIds: [move?.id],
        }),
      disabled: move => !groupableMoveIdsSet?.has(move.id),
    },
    {},
    {
      label: `Search Pickup Address`,
      handler: move => searchLocationOnGoogleMaps(move?.lane?.pickup?.address),
    },
    {
      label: `Search Delivery Address`,
      handler: move => searchLocationOnGoogleMaps(move?.lane?.delivery?.address),
    },
    {
      label: `Search Directions`,
      handler: move => searchLaneOnGoogleMaps(move?.lane?.pickup?.address, move?.lane?.delivery?.address),
    },
  ];

  function UnassignedMovesListItem({ move = {}, index }) {
    const lane = move.lane || {};
    const customer = move.customer || {};
    const notQualified = disabledMoveIds.includes(move.id);
    const driveType = getDriveTypeFromMove(move);

    // Get the deadline for the move. Use the current time, then add the lane duration and a 15 buffer.
    const calcTime = dayjs().add(move?.lane?.duration_sec, `seconds`).add(15, `minutes`).format();
    const deadlineMs = getMoveDeadline(move?.deliver_by, calcTime);

    const getDriveIcon = () => {
      if (driveType === `loaner`) return `emoji_transportation`;
      if (driveType === `concierge`) return `person_pin`;
      return `drive_eta`;
    };

    const getMoveRegion = lane?.pickup?.region
      ? lane?.pickup?.region?.name
      : lane?.delivery?.region?.name || `No Region Available`;

    return (
      <Draggable
        draggableId={`${move.id}`}
        //isDragDisabled={enrichedMove.isDraggable}
        value={move.id}
        key={move.id}
        index={index}
      >
        {(provided, snapshot) => {
          //log(`Rendering draggable unassigned move ${move.id} with index ${index}`);
          if (typeof provided.draggableProps.onTransitionEnd === 'function') {
            queueMicrotask(() =>
              provided.draggableProps.onTransitionEnd?.({
                propertyName: 'transform',
              })
            );
          }

          const hasHangtag = move?.hangtags?.[0]?.hash_id ? true : false;
          const isGroupable = groupableMoveIdsSet.has(move.id);

          return (
            <div
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
              style={provided.draggableProps.style}
              key={move.id}
              data-toggle='collapse'
              onClick={() => {
                tryToPlanMove(move, undefined, isGroupable);
              }}
              value={move.id}
              aria-expanded='false'
              aria-controls={`draggable-${move.id}`}
              id={`draggable-${move.id}`}
              data-testid={`move`}
            >
              <React.Fragment key={`plans-unassigned-move-${index}`}>
                <div className={notQualified ? cls.disabledMoveItem : cls.unassignedMoveItem}>
                  {/* HIGH-LEVEL DETAILS */}
                  <Grid container alignItems='center' wrap='nowrap'>
                    <Grid item className={cls.gridTitleItem}>
                      <Tooltip placement='top' title={capFirst(driveType)}>
                        <Icon className={driveType === `ops` ? cls.iconOps : cls.iconConcierge}>{getDriveIcon()}</Icon>
                      </Tooltip>
                    </Grid>

                    <Grid item xs className={cls.gridTitleItem}>
                      <Typography className={cls.infoIdTxt}>
                        #{move.id} ({getDurationInMinutes(lane.duration_sec)})
                      </Typography>
                    </Grid>

                    <Grid item className={cls.gridTitleItem}>
                      <Typography
                        className={cls.infoTimeTxt}
                        style={{
                          color: driveType === `ops` ? theme.palette.ops.main : theme.palette.concierge.main,
                        }}
                      >
                        {move?.ready_by
                          ? `Ready by ${dayjs(move?.ready_by).tz(timezoneOverride).format('M/D h:mm A z')}`
                          : `No Ready By`}
                      </Typography>

                      <Spacer size='xxxs' />

                      <Typography
                        className={cls.infoTimeTxt}
                        style={{
                          color: theme.palette.default.lighter,
                        }}
                      >
                        {move?.deliver_by
                          ? `Deadline ${dayjs(move?.deliver_by).tz(timezoneOverride).format('M/D h:mm A z')}`
                          : `No Deadline`}
                      </Typography>
                    </Grid>
                  </Grid>

                  <Spacer size='xxs' />

                  <Grid container spacing={1} alignItems='flex-end' wrap='nowrap'>
                    {/* ADDITIONAL DETAILS */}
                    <Grid item xs className={cls.overflowFix}>
                      <Typography className={cls.infoCustomerTxt}>{customer.name}</Typography>
                      <Typography className={cls.infoRegionTxt}>{getMoveRegion}</Typography>

                      <Typography className={cls.infoLaneTxt}>
                        {lane?.pickup?.name} to {lane?.delivery?.name}
                      </Typography>

                      {driveType === `concierge` || driveType === `loaner` ? (
                        <Typography className={cls.infoVehicleTxt}>
                          {move.consumer_name} - {getCleansedPhoneNumber(move.consumer_phone)}
                        </Typography>
                      ) : null}

                      {driveType === `ops` ? (
                        <Typography className={cls.infoVehicleTxt}>{getFormattedVehicleFromMove(move)}</Typography>
                      ) : null}

                      <div className={cls.fadeFix} />
                    </Grid>

                    {/* HANGTAG */}
                    {hasHangtag && move?.hangtags?.[0]?.type === `yard` ? (
                      <Grid item>
                        <MoveTileHangtag hangtag={move?.hangtags?.[0]} parent={`unassigned-move-tile`} />
                      </Grid>
                    ) : null}

                    {/* ICON GRID */}
                    {isGroupable ||
                    move?.consumer_name ||
                    move?.consumer_phone ||
                    move?.manual_flag ||
                    move?.move_details ||
                    move?.tags ? (
                      <Grid item className={cls.iconGrid}>
                        {isGroupable ? (
                          <Tooltip placement='top' title={`Move is potentially groupable with other moves`}>
                            <Icon className={cls.icon}>hub</Icon>
                          </Tooltip>
                        ) : null}

                        {move.consumer_name || move.consumer_phone ? (
                          <Tooltip
                            placement='top'
                            title={
                              <>
                                <div>Consumer Name: {move.consumer_name || `-`}</div>
                                <div>Consumer Phone: {getCleansedPhoneNumber(move.consumer_phone) || `-`}</div>
                                <div>Vehicle: {getFormattedVehicleFromMove(move)}</div>
                              </>
                            }
                          >
                            <Icon className={cls.icon}>person</Icon>
                          </Tooltip>
                        ) : null}

                        {move.manual_flag ? (
                          <Tooltip placement='top' title='Manual Transmission'>
                            <ManualIcon className={cls.icon} />
                          </Tooltip>
                        ) : null}

                        {move.move_details ? (
                          <Tooltip placement='top' title={`Notes/Instructions: ${move.move_details}`}>
                            <Icon className={cls.icon}>assignment</Icon>
                          </Tooltip>
                        ) : null}

                        {move.tags ? (
                          <Tooltip placement='top' title={`Tags: ${move.tags}`}>
                            <Icon className={cls.icon}>more</Icon>
                          </Tooltip>
                        ) : null}
                      </Grid>
                    ) : null}
                  </Grid>

                  <Tooltip title='Driver does not meet the criteria of this move!'>
                    <div className={notQualified ? cls.disabledOverlay : ``} />
                  </Tooltip>

                  <div className={cls.deadline}>
                    <DeadlineChip
                      move={move}
                      deadlineMs={deadlineMs}
                      calc='unassigned'
                      calcTime={calcTime}
                      hide={Number(deadlineMs) && deadlineMs <= 86400000 ? false : true} // Show only if deadline is less than 24 hours
                    />
                  </div>
                </div>

                <div className={cls.lineBreak} />
              </React.Fragment>
            </div>
          );
        }}
      </Draggable>
    );
  }

  return (
    <div className={cls.container}>
      <div className={cls.filter}>
        <Collapse in={showFilters}>
          <div className={cls.filterBox}>
            <Grid container spacing={1} wrap='nowrap'>
              <AppBar
                position='static'
                color='default'
                marginBottom='8px'
                elevation='0'
                style={{ borderRadius: '8px' }}
              >
                <Tabs
                  value={moveType}
                  onChange={(event, newValue) => handleMoveTypeChange(newValue)} // Pass the new tab value
                  indicatorColor='inherit'
                  textColor='inherit'
                  aria-label='move type tabs'
                  className={cls.tabContainer}
                >
                  <Tab label='Any Type' value='any' className={moveType === 'any' ? cls.anyTab : cls.tab} />
                  <Tab
                    label='Concierge'
                    value='concierge'
                    className={moveType === 'concierge' ? cls.conciergeTab : cls.tab}
                  />
                  <Tab label='Operational' value='ops' className={moveType === 'ops' ? cls.opsTab : cls.tab} />
                </Tabs>
              </AppBar>
            </Grid>

            <Spacer size='lg' />

            <Grid container spacing={1} wrap='nowrap'>
              <Grid item xs>
                <TextField
                  fullWidth
                  select
                  name='date'
                  label='Ready By'
                  placeholder='Select a date filter...'
                  size='small'
                  variant='outlined'
                  value={date}
                  onChange={handleInput(`date`)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment style={{ verticalAlign: 'top' }} position='start'>
                        <Icon color='disabled' fontSize='small'>
                          today
                        </Icon>
                      </InputAdornment>
                    ),
                  }}
                >
                  <MenuItem value='timeline date'>Timeline Date</MenuItem>
                  <MenuItem value='today'>Today</MenuItem>
                  <MenuItem value='month'>This Month</MenuItem>
                  <Divider />
                  <MenuItem value='yesterday'>Yesterday</MenuItem>
                  <MenuItem value='past 7 days'>Past 7 Days</MenuItem>
                  <MenuItem value='past 60 days'>Past 60 Days</MenuItem>
                  <MenuItem value='past'>All Past Moves</MenuItem>
                  <Divider />
                  <MenuItem value='tomorrow'>Tomorrow</MenuItem>
                  <MenuItem value='next 7 days'>Next 7 Days</MenuItem>
                  <MenuItem value='next 60 days'>Next 60 Days</MenuItem>
                  <MenuItem value='future'>All Future Moves</MenuItem>
                  <Divider />
                  <MenuItem value='any'>Any Day</MenuItem>
                </TextField>
              </Grid>

              <Grid item xs>
                <TextField
                  fullWidth
                  select
                  name='timeLeft'
                  label='Time Left To Plan'
                  placeholder='Select a time filter...'
                  size='small'
                  variant='outlined'
                  value={timeLeft}
                  onChange={handleInput(`timeLeft`)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment style={{ verticalAlign: 'top' }} position='start'>
                        <Icon color='disabled' fontSize='small'>
                          history_toggle_off
                        </Icon>
                      </InputAdornment>
                    ),
                  }}
                >
                  <MenuItem value='any'>Any Time</MenuItem>
                  <MenuItem value={0}>Past Due</MenuItem>
                  <MenuItem value={1}>1hr or Less</MenuItem>
                  <MenuItem value={2}>2hrs or Less</MenuItem>
                  <MenuItem value={5}>5hrs or Less</MenuItem>
                  <MenuItem value={15}>15hrs or Less</MenuItem>
                </TextField>
              </Grid>
            </Grid>

            <Spacer size='sm' />

            <CustomerMultiselect
              selectAllItem
              selectedCustomerIdArray={customerIdArray}
              handleChangeCustomers={handleChangeCustomers}
              listSelected={true}
            />

            <Spacer size='sm' />

            <ReusableMultiselect
              dataArray={regions}
              selectedArray={selectedRegionIds}
              optionName={'name'}
              label={'Region'}
              handleOutput={handleChangeRegions}
              listSelected={true}
            />

            <Spacer size='sm' />

            <TextField
              fullWidth
              name='search'
              label='Search'
              placeholder='Search Unassigned Moves...'
              size='small'
              variant='outlined'
              value={search}
              onChange={handleSearchInput}
              InputProps={{
                startAdornment: (
                  <InputAdornment style={{ verticalAlign: 'top' }} position='start'>
                    <Icon color='disabled' fontSize='small'>
                      search
                    </Icon>
                  </InputAdornment>
                ),
              }}
            />

            <Spacer size='sm' />

            <Grid container spacing={1} wrap='nowrap'>
              <Grid item xs>
                <Button
                  className={cls.btn}
                  fullWidth
                  variant={filterType === `any` ? `text` : `contained`}
                  size='small'
                  onClick={() => handleFilterTypeChange()}
                >
                  {getFilterTypeIcon()}
                  {getFilterTypeText()}
                </Button>
              </Grid>

              <Grid item>
                <Tooltip placement='top' title='Clear Filters'>
                  <Button
                    className={cls.iconBtn}
                    variant='outlined'
                    color='primary'
                    onClick={handleClearFilters}
                    disabled={!checkIfFilters()}
                  >
                    Clear Filters
                  </Button>
                </Tooltip>
              </Grid>
            </Grid>
          </div>
        </Collapse>

        <div className={cls.filterBtn} onClick={() => handleShowFilters()}>
          <Icon className={cls.filterIcon}>{showFilters ? `arrow_drop_up` : `arrow_drop_down`}</Icon>
          <Typography className={cls.filterBtnText}>{showFilters ? `Hide Filters` : `Show Filters`}</Typography>
          <Icon className={cls.filterIcon}>{showFilters ? `arrow_drop_up` : `arrow_drop_down`}</Icon>
        </div>
      </div>

      <div className={cls.unassignedMovesList}>
        {filteredMoves && filteredMoves.length > 0 ? (
          <Droppable droppableId='droppable-unassigned' direction='vertical'>
            {(provided, snapshot) => (
              <div id='droppable-unassigned' ref={provided.innerRef} {...provided.droppableProps}>
                {filteredMoves.map((move, i) => (
                  <React.Fragment key={`unassigned-move-item-${move.id}`}>
                    <ContextMenuTrigger id={`unassigned-move-${move.id}-cm`} source={`unassigned-move-${move.id}-cm`}>
                      <UnassignedMovesListItem move={move} index={i} />
                    </ContextMenuTrigger>

                    <ContextMenu id={`unassigned-move-${move.id}-cm`}>
                      {unassignedMoveActions.map(action => {
                        if (!action || action.hide) return null;
                        if (!action.handler)
                          return <Divider key={`unassigned-move-action-${i}`} className={cls.divider} />;
                        return (
                          <ContextMenuItem
                            data={{ action: action, ...action.data }}
                            key={`unassigned-move-action-${action.label}`}
                            disabled={typeof action.disabled === 'function' ? action.disabled(move) : action.disabled}
                            onClick={() => action.handler(move)}
                          >
                            {action.label}
                          </ContextMenuItem>
                        );
                      })}
                    </ContextMenu>
                  </React.Fragment>
                ))}
              </div>
            )}
          </Droppable>
        ) : (
          <DefaultEmptyFallback message='No Moves Found' />
        )}
      </div>
    </div>
  );
}

//////////////////////// STYLES ////////////////////////

const useStyles = makeStyles(theme => ({
  container: {
    position: 'relative',
  },

  filter: {
    zIndex: 251,
    position: 'sticky',
    top: 0,
    left: 0,
  },
  filterBox: {
    zIndex: 252,
    padding: theme.spacing(1.5),
    background: theme.palette.background.paper,
    borderBottom: theme.border[0],
  },
  filterBtn: {
    zIndex: 256,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(1, 2),
    borderRadius: '0 0 8px 8px',
    background: theme.palette.background.paper,
    cursor: 'pointer',
    boxShadow: theme.shadow.medium,
  },
  filterBtnText: {
    textTransform: 'uppercase',
    fontSize: 14,
    fontWeight: 500,
    color: theme.palette.text.secondary,
  },
  filterIcon: {
    display: 'block',
    fontSize: 24,
    color: theme.palette.text.secondary,
  },
  filterBtnIcon: {
    display: 'block',
    marginTop: -2,
    marginLeft: -4,
    marginRight: 8,
    fontSize: 16,
  },

  unassignedMovesList: {},
  unassignedMoveItem: {
    position: 'relative',
    padding: theme.spacing(1.5),
    paddingRight: theme.spacing(2),
    background: theme.palette.background.paper,
    '& $fadeFix': {
      background: `linear-gradient(90deg, ${theme.palette.background.paper}00, ${theme.palette.background.paper})`,
    },
    '& $iconGrid': {
      background: theme.palette.action.hover,
    },
    '&:hover': {
      background: theme.palette.background.dark,
      '& $fadeFix': {
        background: `linear-gradient(90deg, ${theme.palette.background.dark}00, ${theme.palette.background.dark})`,
      },
    },
    cursor: 'pointer',
    overflow: 'hidden',
  },
  disabledMoveItem: {
    position: 'relative',
    padding: theme.spacing(1.5),
    paddingRight: theme.spacing(2),
    background: theme.palette.background.dark,
    '& $fadeFix': {
      background: `linear-gradient(90deg, ${theme.palette.background.dark}00, ${theme.palette.background.dark})`,
    },
    '& $iconGrid': {
      background: theme.palette.action.hover,
    },
    cursor: 'not-allowed',
    overflow: 'hidden',
  },
  disabledOverlay: {
    zIndex: 300,
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    background: '#ffffffa0',
  },
  gridTitleItem: {
    maxHeight: 24,
  },
  btn: {
    height: 32,
    background: `${theme.palette.default.main}24`,
  },
  iconGrid: {
    maxHeight: 32,
    padding: theme.spacing(0.5),
    borderRadius: theme.shape.borderRadius,
    marginBottom: 4,
  },
  iconBtn: {
    padding: theme.spacing(0.5),
  },
  icon: {
    color: theme.palette.text.secondary,
  },
  iconOps: {
    color: theme.palette.ops.main,
    marginRight: theme.spacing(1),
  },
  iconConcierge: {
    color: theme.palette.concierge.main,
    marginRight: theme.spacing(1),
  },
  overflowFix: {
    position: 'relative',
    minWidth: 0,
  },
  fadeFix: {
    position: 'absolute',
    top: 0,
    right: 0,
    width: 24,
    height: '100%',
    paddingRight: theme.spacing(0.5),
  },
  infoIdTxt: {
    marginRight: theme.spacing(1),
    lineHeight: 1,
    fontSize: 14,
    fontWeight: 600,
    whiteSpace: 'noWrap',
    overflow: 'hidden',
    textOverflow: 'clip',
  },
  infoTimeTxt: {
    zIndex: 250,
    lineHeight: 1,
    textAlign: 'right',
    fontSize: 12,
    fontWeight: 600,
    whiteSpace: 'noWrap',
    overflow: 'hidden',
    textOverflow: 'clip',
  },
  infoCustomerTxt: {
    lineHeight: 1.25,
    fontSize: 12,
    fontWeight: 600,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'clip',
  },
  infoRegionTxt: {
    marginBottom: 6,
    lineHeight: 1.25,
    fontSize: 10,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'clip',
  },
  infoLaneTxt: {
    lineHeight: 1.25,
    fontSize: 12,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'clip',
  },
  infoVehicleTxt: {
    marginTop: 4,
    lineHeight: 1.25,
    fontSize: 10,
    color: theme.palette.text.secondary,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'clip',
  },
  lineBreak: {
    width: '100%',
    height: 1,
    background: theme.palette.divider,
  },
  divider: {
    marginTop: 4,
    marginBottom: 4,
  },

  deadline: {
    zIndex: 250,
    position: 'absolute',
    top: 40,
    right: 12,
  },
  anyTab: {
    backgroundColor: '#333333',
    color: 'white',
    flexGrow: 1,
    textAlign: 'center',
    minWidth: '80px',
    maxWidth: '150px',
  },
  conciergeTab: {
    backgroundColor: theme.palette.concierge.main,
    color: 'white',
    flexGrow: 1,
    textAlign: 'center',
    minWidth: '80px',
    maxWidth: '200px',
  },
  opsTab: {
    backgroundColor: theme.palette.ops.main,
    color: 'white',
    flexGrow: 1,
    textAlign: 'center',
    minWidth: '80px',
    maxWidth: '200px',
  },
  tab: {
    flexGrow: 1,
    textAlign: 'center',
    minWidth: '80px',
    maxWidth: '200px',
    '&:hover': {
      backgroundColor: theme.palette.text.secondary,
      color: 'white',
    },
  },
  tabContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderRadius: '8px',
    width: '100%',
    maxWidth: '600px',
    margin: '0 auto',
    minHeight: '40px',
    maxHeight: '40px',
  },
}));
