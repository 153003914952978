import React from 'react';
import { makeStyles, TableCell, TableRow, Collapse } from '@material-ui/core';
import { ContextMenu, MenuItem, ContextMenuTrigger } from 'react-contextmenu';

import { useTools } from '../../hooks/useTools';

import APDetailTable from '../../components/APDetailTable';

//////////////////////// COMPONENT ////////////////////////

export default function DriverPayTableRow(props) {
  const { row, index, foldId, setFoldId } = props;
  const driverPay = row.driverPay || {};

  const cls = useStyles();

  const { goToPayoutDetails } = useTools();

  const handleRowFold = () => {
    if (foldId === row.foldId) setFoldId(0);
    else setFoldId(row.foldId);
  };

  // Set a list of cells to render the row
  const rowCells = [
    {
      value: row.checkbox,
      align: `left`,
    },
    {
      value: row.avatar || `-`,
      align: `left`,
      onClick: () => handleRowFold(),
    },
    {
      value: row.driver || `-`,
      align: `left`,
      onClick: () => handleRowFold(),
    },
    {
      value: row.direct_deposit || `-`,
      align: `left`,
      onClick: () => handleRowFold(),
    },
    {
      value: row.tax_class || `-`,
      align: `left`,
      onClick: () => handleRowFold(),
    },
    {
      value: row.drive_move_count || `-`,
      align: `left`,
      onClick: () => handleRowFold(),
    },
    {
      value: row.ride_move_count || `-`,
      align: `left`,
      onClick: () => handleRowFold(),
    },
    {
      value: row.accessorial_count || `-`,
      align: `left`,
      onClick: () => handleRowFold(),
    },
    {
      value: row.issues || `-`,
      align: `left`,
      onClick: () => handleRowFold(),
    },
    {
      value: row.total || `-`,
      align: `right`,
      onClick: () => handleRowFold(),
    },
  ];

  // Set a list of actions after right-clicking on a row
  const rowActions = [
    {
      label: `Go To Payout Details`,
      handler: () => goToPayoutDetails(row.driverPay.driverpayouts[0].id),
      disabled: !row.driverPay.driverpayouts.length,
    },
  ];

  return (
    <>
      <ContextMenuTrigger
        id={`atr-row-${index}-cm`}
        source={`atr-row-${index}-cm`}
        holdToDisplay={1000}
        collect={() => props}
        disableIfShiftIsPressed={true}
        renderTag='tr'
        attributes={{
          className: `MuiTableRow-root ${foldId === row.foldId ? cls.rowActive : index % 2 ? cls.rowEven : cls.rowOdd}`,
        }}
      >
        {rowCells && rowCells.length > 0
          ? rowCells.map((cell, i) => {
              if (!cell.hide)
                return (
                  <TableCell
                    key={`atr-row-${index}-col-${i}`}
                    className={foldId === row.foldId ? cls.cellActive : cls.cell}
                    align={cell.align || `left`}
                    onClick={cell.onClick || null}
                  >
                    {cell.value || null}
                  </TableCell>
                );
              else return null;
            })
          : null}
      </ContextMenuTrigger>

      {rowActions && rowActions.length > 0 && (
        <ContextMenu id={`atr-row-${index}-cm`}>
          {rowActions.map(
            (action, i) =>
              !action.hide && (
                <MenuItem
                  key={`atr-row-action-${i}`}
                  disabled={action.disabled || false}
                  onClick={action.handler || null}
                >
                  {action.label || `Action ${i + 1}`}
                </MenuItem>
              )
          )}
        </ContextMenu>
      )}

      <TableRow>
        <TableCell className={cls.collapseCell} colSpan={rowCells.length}>
          <Collapse className={cls.collapse} in={foldId === row.foldId}>
            <div className={cls.collapseBox}>
              <APDetailTable driverPay={driverPay} />
            </div>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

//////////////////////// STYLES ////////////////////////

const useStyles = makeStyles(theme => ({
  rowOdd: {
    background: theme.palette.background.paper,
    '&:hover, &:active': {
      background: `${theme.palette.action.hover} !important`,
    },
    cursor: 'pointer',
    transition: '0.1s',
  },
  rowEven: {
    background: theme.palette.background.light,
    '&:hover, &:active': {
      background: `${theme.palette.action.hover} !important`,
    },
    cursor: 'pointer',
    transition: '0.1s',
  },
  rowActive: {
    background: theme.palette.action.focus,
    cursor: 'pointer',
    transition: '0.1s',
  },

  cell: {
    color: theme.palette.text.primary,
    transition: '0.1s',
  },
  cellActive: {
    color: theme.palette.text.primary,
    transition: '0.1s',
  },

  collapseCell: {
    padding: '0 !important',
    border: 'none',
  },
  collapse: {
    borderBottom: theme.border[0],
  },
  collapseBox: {
    padding: theme.spacing(2),
    background: theme.palette.background.main,
  },
}));
