import React from 'react';
import { makeStyles, Container } from '@material-ui/core';
import { Loading } from '@hopdrive/storybook';

import * as Sentry from '@sentry/react';
import { gql, useQuery } from '@apollo/client';

import { useTools } from '../../hooks/useTools';

import { DefaultErrorFallback, DefaultEmptyFallback } from '../../components/Fallbacks';
import LaunchPad from './LaunchPad';

const log = false;

//////////////////////////////////////// COMPONENT ////////////////////////////////////////

export default function Onboarding(props) {
  const cls = useStyles();

  const driverId = props.match.params.id;

  const { spreadDriverObj } = useTools();

  const { loading, error, data, refetch } = useQuery(GET_DRIVER, {
    variables: { driverId: driverId || null },
    fetchPolicy: 'network-only',
  });

  // Handle refetch callback to pass as props
  const handleRefetch = () => {
    refetch();
  };

  // LOADING STATE //
  if (loading) {
    return <Loading position='fixed' />;
  }

  // ERROR STATE //
  if (error) {
    console.error(`Error getting onboarding driver details:`, error);
    Sentry.captureException(error);
    return (
      <div className={cls.root}>
        <Container maxWidth='lg'>
          <DefaultErrorFallback message='ERROR GETTING ONBOARDING DRIVER DETAILS' />
        </Container>
      </div>
    );
  }

  // EMPTY STATE //
  if (!data || !data.drivers || !data.drivers.length > 0) {
    return (
      <div className={cls.root}>
        <Container maxWidth='lg'>
          <DefaultEmptyFallback message='NO DRIVER FOUND' />
        </Container>
      </div>
    );
  }

  // DATA STATE //
  const driverDB = data.drivers[0];
  const driver = spreadDriverObj(driverDB);
  log && console.log(`Driver:`, driver);

  return <LaunchPad driver={driver} refetch={handleRefetch} />;
}

//////////////////////// STYLES ////////////////////////

const useStyles = makeStyles(theme => ({
  root: {
    display: 'block',
    position: 'relative',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(7),
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
    },
    [theme.breakpoints.down('xs')]: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
  },
}));

//////////////////////////////////////// GRAPHQL ////////////////////////////////////////

const GET_DRIVER = gql`
  query get_driver($driverId: bigint!) {
    drivers(where: { id: { _eq: $driverId } }) {
      id
      bank_account_token
      config
      phone_type
      plate_id
      region_id
      status
      tax_class
      user_id
      vehicle_color
      vehicle_license_plate
      vehicle_license_plate_state
      vehicle_make
      vehicle_model
      vehicle_vin
      vehicle_year
      verification
      wallet_token
      updated_at
      driverdetail {
        address_city
        address_one
        address_state
        address_two
        address_zip
        date_of_birth
        emergency_name_one
        emergency_name_two
        emergency_phone_one
        emergency_phone_two
        emergency_relationship_one
        emergency_relationship_two
        employer_identifier
        first_name
        gender
        insurance_coverage_amount
        insurance_policy_id
        insurance_provider
        insurance_rideshare_rider
        last_name
        license_number
        license_photo_url
        license_state
        mail_address_city
        mail_address_one
        mail_address_state
        mail_address_two
        mail_address_zip
        middle_name
        primary_phone
        social_security
        veteran
      }
      user {
        id
        active
        avatar_url
        display_name
        email
        phone
        default_role
      }
    }
  }
`;
