import React from 'react';
import { makeStyles, Grid, Typography, InputBase, TextField, MenuItem, Tooltip } from '@material-ui/core';
import { Spacer } from '@hopdrive/storybook';
import { getPropValue } from '@hopdrive/sdk/lib/modules/utilities';
import DriverAppVersionChip from '../../components/DriverAppVersionChip';

import { useTools } from '../../hooks/useTools';
import { useDriverDetails } from './DriverDetailsProvider';
import CustomSwitch from '../../components/CustomSwitch';
import DriverImageDropzone from './DriverImageDropzone';

const phoneTypeOptions = [`android`, `ios`, `other`];
const taxClassOptions = [`1099`, `W-2`];
const genderOptions = [`male`, `female`, `other`];

//////////////////////// COMPONENT ////////////////////////

export default function DriverInfo({ driver = {} }) {
  const cls = useStyles();
  const ctx = useDriverDetails();

  const { capFirst, getFormattedStatusFromDriver, getInitialsFromName, getCleansedPhoneNumber, getCleansedPhoneType } =
    useTools();

  const handleInputChange = setHandler => event => {
    if (setHandler) setHandler(event.target.value);
  };
  const handleSwitch = (preValue, setHandler) => {
    if (setHandler) {
      if (preValue) setHandler(false);
      else setHandler(true);
    }
  };

  return (
    <Grid container spacing={2} alignItems='center'>
      <Grid item>
        <>
          {ctx.editMode ? (
            <DriverImageDropzone
              driver={driver}
              defaultImageUrl={driver?.avatar_url}
              onImageUrlChange={ctx.setAvatarUrl}
            />
          ) : (
            <>
              {driver.avatar_url ? (
                <div className={cls.avatar}>
                  <img className={cls.avatarImg} src={driver.avatar_url} alt='avatar' />
                </div>
              ) : (
                <div className={cls.avatar}>
                  <div className={cls.avatarImg}>
                    <Typography className={cls.initialsTxt}>
                      {getInitialsFromName({ driver_name: driver.display_name })}
                    </Typography>
                  </div>
                </div>
              )}
            </>
          )}
        </>
      </Grid>

      <Grid item xs>
        <div className={cls.paper}>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <Tooltip placement='top-start' title='Driver ID'>
                <Typography className={cls.headTxt} style={{ textAlign: 'left' }}>
                  {driver.id ? `Driver #${driver.id}` : `No Driver ID`}
                </Typography>
              </Tooltip>
            </Grid>

            <Grid item xs={4}>
              <Tooltip placement='top' title='Driver Name'>
                <Typography className={cls.headTxt} style={{ textAlign: 'center' }}>
                  {driver.display_name || `HopDriver`}
                </Typography>
              </Tooltip>
            </Grid>

            <Grid item xs={4}>
              <Tooltip placement='top-end' title='Driver Status'>
                <Typography className={cls.headTxt} style={{ textAlign: 'right' }}>
                  {getFormattedStatusFromDriver(driver)}
                </Typography>
              </Tooltip>
            </Grid>
          </Grid>

          <Spacer />

          <Grid container spacing={2}>
            <Grid item md={6} xs={12}>
              <div className={cls.listItem}>
                <Typography className={cls.keyTxt}>Email:</Typography>
                {ctx.editMode ? (
                  <InputBase
                    value={ctx.email}
                    onChange={handleInputChange(ctx.setEmail)}
                    className={cls.nakedValTxt}
                    inputProps={{ className: cls.nakedValInput }}
                  />
                ) : (
                  <Typography className={cls.valTxt}>{driver.email || `-`}</Typography>
                )}
              </div>

              <div className={cls.lineBreak} />

              <div className={cls.listItem}>
                <Typography className={cls.keyTxt}>Phone:</Typography>
                {ctx.editMode ? (
                  <InputBase
                    value={ctx.phone}
                    onChange={handleInputChange(ctx.setPhone)}
                    className={cls.nakedValTxt}
                    inputProps={{ className: cls.nakedValInput }}
                  />
                ) : (
                  <Typography className={cls.valTxt}>{getCleansedPhoneNumber(driver.phone) || `-`}</Typography>
                )}
              </div>

              <div className={cls.lineBreak} />

              <div className={cls.listItem}>
                <Typography className={cls.keyTxt}>Phone Type:</Typography>
                {ctx.editMode ? (
                  <TextField
                    select
                    value={ctx.phoneType}
                    onChange={handleInputChange(ctx.setPhoneType)}
                    className={cls.nakedValTxt}
                    inputProps={{ className: cls.nakedValInput }}
                  >
                    {phoneTypeOptions.map((pto, i) => (
                      <MenuItem key={`phone-type-option-${i}`} value={pto}>
                        {getCleansedPhoneType(pto)}
                      </MenuItem>
                    ))}
                  </TextField>
                ) : (
                  <Typography className={cls.valTxt}>{getCleansedPhoneType(driver.phone_type) || `-`}</Typography>
                )}
              </div>

              <div className={cls.lineBreak} />

              <div className={cls.listItem}>
                <Typography className={cls.keyTxt}>Gender:</Typography>
                {ctx.editMode ? (
                  <TextField
                    select
                    value={ctx.gender}
                    onChange={handleInputChange(ctx.setGender)}
                    className={cls.nakedValTxt}
                    inputProps={{ className: cls.nakedValInput }}
                  >
                    {genderOptions.map((go, i) => (
                      <MenuItem key={`gender-option-${i}`} value={go}>
                        {capFirst(go)}
                      </MenuItem>
                    ))}
                  </TextField>
                ) : (
                  <Typography className={cls.valTxt}>{capFirst(driver.gender) || `-`}</Typography>
                )}
              </div>

              <div className={cls.lineBreak} />

              <div className={cls.listItem}>
                <Typography className={cls.keyTxt}>Veteran:</Typography>
                <Typography className={cls.valTxt}>{getPropValue(driver, `veteran.status`) ? `Yes` : `No`}</Typography>
              </div>

              <div className={cls.lineBreak} />

              <div className={cls.listItem}>
                <Typography className={cls.keyTxt}>Date of Birth:</Typography>
                {ctx.editMode ? (
                  <InputBase
                    value={ctx.dob}
                    onChange={handleInputChange(ctx.setDob)}
                    className={cls.nakedValTxt}
                    inputProps={{ className: cls.nakedValInput }}
                  />
                ) : (
                  <Typography className={cls.valTxt}>{driver.date_of_birth || `-`}</Typography>
                )}
              </div>
            </Grid>

            <div className={cls.hiddenBreak} />

            <Grid item md={6} xs={12}>
              <div className={cls.listItem}>
                <Typography className={cls.keyTxt}>Active:</Typography>
                <CustomSwitch
                  editMode={ctx.editMode}
                  checked={ctx.editMode ? ctx.active : driver.active}
                  onChange={() => handleSwitch(ctx.active, ctx.setActive)}
                />
              </div>

              <div className={cls.lineBreak} />

              <div className={cls.listItem}>
                <Typography className={cls.keyTxt}>Region:</Typography>
                {ctx.editMode ? (
                  <TextField
                    select
                    value={ctx.regionId}
                    onChange={handleInputChange(ctx.setRegionId)}
                    className={cls.nakedValTxt}
                    inputProps={{ className: cls.nakedValInput }}
                  >
                    {ctx.regions.map((ro, i) => (
                      <MenuItem key={`region-option-${i}`} value={ro.id}>
                        {ro.name} ({ro.id})
                      </MenuItem>
                    ))}
                  </TextField>
                ) : (
                  <Typography className={cls.valTxt}>
                    {driver.region_name && driver.region_id ? `${driver.region_name} (${driver.region_id})` : `-`}
                  </Typography>
                )}
              </div>

              <div className={cls.lineBreak} />

              <div className={cls.listItem}>
                <Typography className={cls.keyTxt}>Tax Class:</Typography>
                {ctx.editMode ? (
                  <TextField
                    select
                    value={ctx.taxClass}
                    onChange={handleInputChange(ctx.setTaxClass)}
                    className={cls.nakedValTxt}
                    inputProps={{ className: cls.nakedValInput }}
                  >
                    {taxClassOptions.map((tco, i) => (
                      <MenuItem key={`tax-class-option-${i}`} value={tco}>
                        {tco}
                      </MenuItem>
                    ))}
                  </TextField>
                ) : (
                  <Typography className={cls.valTxt}>{driver.tax_class || `-`}</Typography>
                )}
              </div>

              <div className={cls.lineBreak} />

              <div className={cls.listItem}>
                <Typography className={cls.keyTxt}>Wallet Token:</Typography>
                <Typography className={cls.valTxt}>{driver.wallet_token || `-`}</Typography>
              </div>

              <div className={cls.lineBreak} />

              <div className={cls.listItem}>
                <Typography className={cls.keyTxt}>Account Token:</Typography>
                <Typography className={cls.valTxt}>{driver.bank_account_token || `-`}</Typography>
              </div>

              <div className={cls.lineBreak} />

              <div className={cls.listItem}>
                <Typography className={cls.keyTxt}>Driver App Version:</Typography>
                {ctx.editMode ? (
                  <InputBase
                    value={ctx.driverAppVersion}
                    onChange={handleInputChange(ctx.setDriverAppVersion)}
                    className={cls.nakedValTxt}
                    inputProps={{ className: cls.nakedValInput }}
                  />
                ) : (
                  <Typography className={cls.valTxt}>
                    <DriverAppVersionChip version={driver.driver_app_version} />
                  </Typography>
                )}
              </div>
            </Grid>
          </Grid>
        </div>
      </Grid>
    </Grid>
  );
}

//////////////////////// STYLES ////////////////////////

const useStyles = makeStyles(theme => ({
  paper: {
    position: 'relative',
    width: '100%',
    padding: theme.spacing(2),
    borderRadius: theme.shape.paperRadius,
    background: theme.palette.background.paper,
    boxShadow: theme.shadow.medium,
  },
  avatar: {
    position: 'relative',
    width: 160,
    height: 160,
  },
  avatarImg: {
    position: 'relative',
    objectFit: 'cover',
    width: 160,
    height: 160,
    borderRadius: '50%',
    background: theme.palette.fade[3],
    boxShadow: theme.shadow.medium,
  },
  initialsTxt: {
    position: 'absolute',
    top: '15%',
    left: 0,
    right: 0,
    color: theme.palette.text.contrast,
    textAlign: 'center',
    fontSize: 24,
    fontWeight: 700,
    cursor: 'default',
    userSelect: 'none',
  },
  headTxt: {
    lineHeight: 1.25,
    fontSize: 21,
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: {
      fontSize: 18,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 16,
    },
    cursor: 'default',
  },
  listItem: {
    display: 'flex',
    flexWrap: 'nowrap',
  },
  keyTxt: {
    marginRight: 8,
    lineHeight: 1.25,
    textAlign: 'left',
    whiteSpace: 'nowrap',
    fontSize: 14,
    fontWeight: 400,
    color: theme.palette.text.secondary,
    [theme.breakpoints.down('sm')]: {
      fontSize: 13,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 12,
    },
  },
  valTxt: {
    width: '100%',
    lineHeight: 1.25,
    textAlign: 'right',
    fontSize: 14,
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      fontSize: 13,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 12,
    },
  },
  nakedValTxt: {
    width: '100%',
    lineHeight: 1.25,
    fontSize: 14,
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      fontSize: 13,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 12,
    },
  },
  nakedValInput: {
    width: '100%',
    minHeight: 17,
    maxHeight: 17,
    padding: 0,
    background: '#00000010',
    textAlign: 'right',
  },
  lineBreak: {
    width: '100%',
    height: '1px',
    marginTop: '6px',
    marginBottom: '8px',
    background: '#e0e0e0',
  },
  hiddenBreak: {
    display: 'none',
    width: '100%',
    height: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
  },
}));
