// DEPENDENCIES ---------------------------------------------------------------- //

import React from 'react';
import { toast } from 'react-toastify';

import {
  Icon,
  IconButton,
  InputAdornment,
  makeStyles,
  MenuItem,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from '@material-ui/core';

import { useTools } from '../../hooks/useTools';

import { REACT_APP_ACC_CODES } from '../../utils/env';

const USDRegex = new RegExp(`^[+-]?[0-9]{1,3}(?:,?[0-9]{3})*(?:\\.[0-9]{1,2})?$`);

// COMPONENT ---------------------------------------------------------------- //

export default function MoveOutcomeReviewAccessorialRow({
  accessorial,
  showControls,
  isEditing,
  isModified,
  isNew,
  isDeleted,
  onApplyEdits,
  onDiscardEdits,
  onEditAccessorial,
  onDeleteAccessorial,
  onRestoreAccessorial,
}) {
  const theme = useTheme();
  const cls = useStyles();
  const { capFirst, formatUSD } = useTools();

  const [code, setCode] = React.useState(accessorial?.code);
  const [cost, setCost] = React.useState(accessorial?.cost);
  const [arAmount, setArAmount] = React.useState(accessorial?.ar_amount);
  const [apAmount, setApAmount] = React.useState(accessorial?.ap_amount);
  const [notes, setNotes] = React.useState(accessorial?.notes);

  const [validity, setValidity] = React.useState({
    code: false,
    cost: false,
    arAmount: false,
    apAmount: false,
    notes: false,
  });

  // Set the state to edit the accessorials
  React.useEffect(() => {
    if (isEditing) {
      setCode(accessorial?.code);
      setCost(accessorial?.cost);
      setArAmount(accessorial?.ar_amount);
      setApAmount(accessorial?.ap_amount);
      setNotes(accessorial?.notes);
    } else {
      setCode(``);
      setCost(0);
      setArAmount(0);
      setApAmount(0);
      setNotes(``);
    }
    // eslint-disable-next-line
  }, [isEditing]);

  // Validate the accessorial in realtime to prevent applying bad accessorials
  React.useEffect(() => {
    // Initialize validity state
    const newValidity = { code: true, cost: true, arAmount: true, apAmount: true, notes: true };

    // Check validity of the code
    if (!code) newValidity.code = false;

    // Check validity of the cost
    if (isNaN(cost) || cost < -1000 || cost > 1000 || !USDRegex.test(cost)) newValidity.cost = false;

    // Check validity of the arAmount
    if (isNaN(arAmount) || arAmount < -1000 || arAmount > 1000 || !USDRegex.test(arAmount))
      newValidity.arAmount = false;

    // Check validity of the apAmount
    if (isNaN(apAmount) || apAmount < -1000 || apAmount > 1000 || !USDRegex.test(apAmount))
      newValidity.apAmount = false;

    // Check validity of the notes
    if (!notes) newValidity.notes = false;

    // Special validity check for waived accessorials (AR and AP must be 0)
    if(code === `waived` && (arAmount !== 0 || apAmount !== 0)) {
      newValidity.arAmount = false;
      newValidity.apAmount = false;
      toast.error(`Waived accessorials must have $0 AR and AP amounts!`, {toastId: `waived-accessorial-error`});
    }

    // Set the validity state
    setValidity(newValidity);
  }, [code, cost, arAmount, apAmount, notes]);

  return (
    <div className={cls.outcomeRow}>
      <div
        className={cls.outcomeCellFlex}
        style={{
          backgroundColor: isDeleted
            ? `${theme.palette.error.main}20`
            : isNew
            ? `${theme.palette.success.main}20`
            : isModified
            ? `${theme.palette.info.main}20`
            : theme.palette.background.main,
        }}
      >
        <Typography className={cls.outcomeCellKeyTxt}>Accessorial Code{isEditing ? ` *` : ``}</Typography>
        {isEditing ? (
          <TextField
            error={!validity.code}
            disabled={accessorial?.id}
            required
            fullWidth
            select
            placeholder='Enter accessorial code...'
            helperText={!validity.code ? `Please select a code!` : ``}
            size='small'
            value={code}
            onChange={e => setCode(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <Icon color='disabled' fontSize='small'>
                    code
                  </Icon>
                </InputAdornment>
              ),
            }}
          >
            {(REACT_APP_ACC_CODES || ``)
              ?.split(`,`)
              ?.sort()
              ?.map(accCode => (
                <MenuItem key={`acc-code-${accCode}`} value={accCode}>
                  {capFirst(accCode)}
                </MenuItem>
              ))}
          </TextField>
        ) : (
          <Typography className={cls.outcomeCellValTxt}>{accessorial?.code || `None`}</Typography>
        )}
      </div>

      <div
        className={cls.outcomeCellFlex}
        style={{
          backgroundColor: isDeleted
            ? `${theme.palette.error.main}20`
            : isNew
            ? `${theme.palette.success.main}20`
            : isModified
            ? `${theme.palette.info.main}20`
            : theme.palette.background.main,
        }}
      >
        <Typography className={cls.outcomeCellKeyTxt}>Cost to HopDrive</Typography>
        {isEditing ? (
          <TextField
            error={!validity.cost}
            fullWidth
            type='number'
            placeholder='Enter cost to HopDrive...'
            helperText={!validity.cost ? `Invalid dollar amount!` : ``}
            size='small'
            value={cost}
            onChange={e => {
              let value = parseFloat(e.target.value);
              if (value < -1000) value = -1000;
              if (value > 1000) value = 1000;
              setCost(value);
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <Icon color='disabled' fontSize='small'>
                    attach_money
                  </Icon>
                </InputAdornment>
              ),
            }}
            inputProps={{ min: -1000, max: 1000, step: 0.01 }}
          />
        ) : (
          <Typography className={cls.outcomeCellValTxt}>{formatUSD(accessorial?.cost)}</Typography>
        )}
      </div>

      <div
        className={cls.outcomeCellFlex}
        style={{
          backgroundColor: isDeleted
            ? `${theme.palette.error.main}20`
            : isNew
            ? `${theme.palette.success.main}20`
            : isModified
            ? `${theme.palette.info.main}20`
            : theme.palette.background.main,
        }}
      >
        <Typography className={cls.outcomeCellKeyTxt}>Charge to Customer</Typography>
        {isEditing ? (
          <TextField
            error={!validity.arAmount}
            fullWidth
            type='number'
            placeholder='Enter charge to customer...'
            helperText={!validity.arAmount ? `Invalid dollar amount!` : ``}
            size='small'
            value={arAmount}
            onChange={e => {
              let value = parseFloat(e.target.value);
              if (value < -1000) value = -1000;
              if (value > 1000) value = 1000;
              setArAmount(value);
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <Icon color='disabled' fontSize='small'>
                    attach_money
                  </Icon>
                </InputAdornment>
              ),
            }}
            inputProps={{ min: -1000, max: 1000, step: 0.01 }}
          />
        ) : (
          <Typography className={cls.outcomeCellValTxt}>{formatUSD(accessorial?.ar_amount)}</Typography>
        )}
      </div>

      <div
        className={cls.outcomeCellFlex}
        style={{
          backgroundColor: isDeleted
            ? `${theme.palette.error.main}20`
            : isNew
            ? `${theme.palette.success.main}20`
            : isModified
            ? `${theme.palette.info.main}20`
            : theme.palette.background.main,
        }}
      >
        <Typography className={cls.outcomeCellKeyTxt}>Pay to Driver</Typography>
        {isEditing ? (
          <TextField
            error={!validity.apAmount}
            fullWidth
            type='number'
            placeholder='Enter pay to driver...'
            helperText={!validity.apAmount ? `Invalid dollar amount!` : ``}
            size='small'
            value={apAmount}
            onChange={e => {
              let value = parseFloat(e.target.value);
              if (value < -1000) value = -1000;
              if (value > 1000) value = 1000;
              setApAmount(value);
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <Icon color='disabled' fontSize='small'>
                    attach_money
                  </Icon>
                </InputAdornment>
              ),
            }}
            inputProps={{ min: -1000, max: 1000, step: 0.01 }}
          />
        ) : (
          <Typography className={cls.outcomeCellValTxt}>{formatUSD(accessorial?.ap_amount)}</Typography>
        )}
      </div>

      <div
        className={cls.outcomeCellFlex}
        style={{
          backgroundColor: isDeleted
            ? `${theme.palette.error.main}20`
            : isNew
            ? `${theme.palette.success.main}20`
            : isModified
            ? `${theme.palette.info.main}20`
            : theme.palette.background.main,
        }}
      >
        <Typography className={cls.outcomeCellKeyTxt}>Notes{isEditing ? ` *` : ``}</Typography>
        {isEditing ? (
          <TextField
            required
            error={!validity.notes}
            fullWidth
            multiline
            placeholder='Enter notes...'
            helperText={!validity.notes ? `Please enter notes!` : ``}
            size='small'
            value={notes}
            onChange={e => setNotes(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <Icon color='disabled' fontSize='small'>
                    comment
                  </Icon>
                </InputAdornment>
              ),
            }}
          />
        ) : (
          <Typography className={cls.outcomeCellValTxt} style={{ fontSize: 12 }}>
            {accessorial?.notes || `...`}
          </Typography>
        )}
      </div>

      {showControls ? (
        <div className={cls.outcomeCell}>
          {isDeleted ? (
            <Tooltip title='Restore Accessorial'>
              <span>
                <IconButton
                  className={cls.outcomeRestoreIconBtn}
                  onClick={() => onRestoreAccessorial(accessorial?.id || accessorial?.tempId)}
                >
                  <Icon>restore</Icon>
                </IconButton>
              </span>
            </Tooltip>
          ) : isEditing ? (
            <>
              <Tooltip title='Apply Edits'>
                <span>
                  <IconButton
                    disabled={
                      !validity?.code ||
                      !validity?.cost ||
                      !validity?.arAmount ||
                      !validity?.apAmount ||
                      !validity?.notes
                    }
                    className={cls.outcomeApplyIconBtn}
                    onClick={() =>
                      onApplyEdits(accessorial?.id || accessorial?.tempId, code, cost, arAmount, apAmount, notes)
                    }
                  >
                    <Icon>check</Icon>
                  </IconButton>
                </span>
              </Tooltip>

              <Tooltip title='Discard Edits'>
                <span>
                  <IconButton
                    className={cls.outcomeDiscardIconBtn}
                    onClick={() => onDiscardEdits(accessorial?.code, accessorial?.notes)}
                  >
                    <Icon>close</Icon>
                  </IconButton>
                </span>
              </Tooltip>
            </>
          ) : (
            <>
              <Tooltip title='Edit Accessorial'>
                <span>
                  <IconButton
                    className={cls.outcomeEditIconBtn}
                    onClick={() => onEditAccessorial(accessorial?.id || accessorial?.tempId)}
                  >
                    <Icon>edit</Icon>
                  </IconButton>
                </span>
              </Tooltip>

              <Tooltip title='Delete Accessorial'>
                <span>
                  <IconButton
                    className={cls.outcomeDeleteIconBtn}
                    onClick={() => onDeleteAccessorial(accessorial?.id || accessorial?.tempId)}
                  >
                    <Icon>delete</Icon>
                  </IconButton>
                </span>
              </Tooltip>
            </>
          )}
        </div>
      ) : null}
    </div>
  );
}

// STYLES ---------------------------------------------------------------- //

const useStyles = makeStyles(theme => ({
  outcomeRow: {
    position: 'relative',
    display: 'flex',
    flexWrap: 'nowrap',
    gap: 4,
    padding: 4,
    border: `1px solid ${theme.palette.text.disabled}`,
    borderRadius: theme.shape.paperRadius,
    background: theme.palette.background.paper,
  },
  outcomeCell: {
    position: 'relative',
    alignSelf: 'center',
    padding: 4,
  },
  outcomeCellFlex: {
    position: 'relative',
    flex: 1,
    padding: 4,
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.background.main,
  },
  outcomeCellKeyTxt: {
    marginBottom: 4,
    lineHeight: 1,
    fontSize: 12,
    color: theme.palette.text.secondary,
  },
  outcomeCellValTxt: {
    lineHeight: 1.25,
    fontSize: 16,
    '&:first-letter': {
      textTransform: 'capitalize',
    },
  },

  outcomeApplyIconBtn: {
    padding: 8,
    color: theme.palette.success.main,
  },
  outcomeDiscardIconBtn: {
    padding: 8,
    color: theme.palette.error.main,
  },
  outcomeEditIconBtn: {
    padding: 8,
    color: theme.palette.text.primary,
  },
  outcomeDeleteIconBtn: {
    padding: 8,
    color: theme.palette.error.main,
  },
  outcomeRestoreIconBtn: {
    padding: 8,
    color: theme.palette.text.primary,
  },
}));
