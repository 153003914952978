//////////////////////// DEPENDENCIES ////////////////////////

import React from 'react';

import { makeStyles, SwipeableDrawer, List, ListSubheader, Divider, Typography } from '@material-ui/core';

import NavSideBarItem from './NavSideBarItem';

const version = require('../../version');

//////////////////////// COMPONENT ////////////////////////

function NavSideBar({ open, onMenu }) {
  const cls = useStyles();

  // Open/Close slidable sidebar drawer
  const handleMenuClose = () => {
    if (onMenu) onMenu(false);
  };

  return (
    <SwipeableDrawer anchor='left' open={open || false} onOpen={() => {}} onClose={() => handleMenuClose()}>
      <div className={cls.list} onClick={() => handleMenuClose()} onKeyDown={() => handleMenuClose()}>
        <div className={cls.branch}>
          <Typography className={cls.branchName}>
            {version.branch !== 'master' || version.branch !== 'HEAD' ? version.branch : ''}
          </Typography>
          <Typography className={cls.branchTag}>{version.tag}</Typography>
        </div>

        <Divider />

        <List>
          <NavSideBarItem route='/plans' label='Plans' icon='subtitles' />
        </List>

        <Divider />

        <List>
          <ListSubheader disableSticky>Internal</ListSubheader>

          <NavSideBarItem route='/notifications' label='Notifications' icon='notifications' />
          <NavSideBarItem route='/moves' label='Moves' icon='drive_eta' />
          <NavSideBarItem route='/locations' label='Locations' icon='store_mall_directory' />
          <NavSideBarItem route='/lanes' label='Lanes' icon='linear_scale' />
          <NavSideBarItem route='/regions' label='Regions' icon='public' />
          <NavSideBarItem route='/users' label='Hopdrive Users' icon='supervised_user_circle' />
        </List>

        <Divider />

        <List>
          <ListSubheader disableSticky>Customer</ListSubheader>

          <NavSideBarItem route='/organizations' label='Organizations' icon='groups' />
          <NavSideBarItem route='/customers' label='Customers' icon='contacts' />
          <NavSideBarItem route='/invoices' label='Invoices' icon='receipt' />
          <NavSideBarItem route='/disputed-moves' label='Disputed Moves' icon='announcement' />
          <NavSideBarItem route='/zero-dollar-moves' label='Zero Dollar Moves' icon='rate_review' />
          <NavSideBarItem route='/ar-report/moves' label='AR Report (Moves)' icon='assignment' />
          <NavSideBarItem route='/ar-report/payments' label='AR Report (Payments)' icon='assignment' />
          <NavSideBarItem route='/accessorial-report' label='Accessorial Report' icon='assignment' />
          <NavSideBarItem route='/products' label='Products' icon='view_list' />
          <NavSideBarItem route='/insurance' label='Insurance' icon='view_list' />
          <NavSideBarItem route='/customer-users' label='Customer Users' icon='supervised_user_circle' />
        </List>

        <Divider />

        <List>
          <ListSubheader disableSticky>Driver</ListSubheader>

          <NavSideBarItem route='/drivers' label='Drivers' icon='group' />
          <NavSideBarItem route='/driver-pay' label='Driver Pay' icon='account_balance_wallet' />
          <NavSideBarItem route='/driver-payouts' label='Payout History' icon='assignment' />
          {/* <NavSideBarItem route='/driver-schedule' label='Driver Schedule' icon='date_range' /> */}
        </List>

        <Divider />

        <List>
          <ListSubheader disableSticky>Utility</ListSubheader>

          {/* <NavSideBarItem route='/driver-emails' label='Driver Emails' icon='email' /> */}
          <NavSideBarItem route='/faq-manager' label='FAQ Manager' icon='help' />
          <NavSideBarItem route='/gps-viewer' label='GPS Viewer' icon='explore' />
          {/* <NavSideBarItem route='/map' label='Map' icon='explore' /> */}
          <NavSideBarItem
            route='/missing-region-locations'
            label='Missing Region Locations'
            icon='not_listed_location'
          />
          <NavSideBarItem route='/move-calculator' label='Move Calculator' icon='monetization_on' />
          <NavSideBarItem route='/quicklinks' label='Quicklinks' icon='highlight_alt' />
          <NavSideBarItem route='/rules' label='Rules' icon='view_list' />
        </List>

        {/* <Divider />

        <List>
          <ListSubheader disableSticky>Legacy</ListSubheader>

          <NavSideBarItem route='/regions-legacy' label='Regions LEGACY' icon='public' />
        </List> */}

        {/* <Divider />

        <List>
          <ListSubheader disableSticky>BETA</ListSubheader>

          <NavSideBarItem route='/regions-beta' label='Regions BETA' icon='public' />
        </List> */}
      </div>
    </SwipeableDrawer>
  );
}

//////////////////////// STYLES ////////////////////////

const useStyles = makeStyles(theme => ({
  list: {
    minWidth: 240,
    maxWidth: 320,
    overflow: typeof InstallTrigger !== 'undefined' ? 'auto' : 'overlay',
  },
  branch: {
    padding: theme.spacing(1),
  },
  branchName: {
    fontSize: 12,
    color: theme.palette.text.secondary,
  },
  branchTag: {
    fontSize: 16,
    color: theme.palette.text.secondary,
  },
}));

//////////////////////// EXPORT ////////////////////////

export default NavSideBar;
