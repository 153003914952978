import React from 'react';
import { toast } from 'react-toastify';
import { useTheme, makeStyles, Grid, Typography, Icon, Divider } from '@material-ui/core';
import { Button, Spacer } from '@hopdrive/storybook';

import useOnboarding from './useOnboarding';
import DriverImageDropzone from '../DriverDetails/DriverImageDropzone';

//////////////////////////////////////// COMPONENT ////////////////////////////////////////

export default function LaunchPadActions({ driver, launchpadGroups, refetch }) {
  const theme = useTheme();
  const cls = useStyles();

  const {
    // getBackgroundCheckStatus,
    getTrainingStatus,
    // runBackgroundCheck,
    initiateTraining,
    completeTraining,
    updateDriverAvatarUrl,
  } = useOnboarding();

  const [isLoading, setIsLoading] = React.useState(false);

  // const backgroundCheckStatus = getBackgroundCheckStatus(driver);
  const ifGroup = launchpadGroups[0];
  const trainingStatus = getTrainingStatus(driver);

  const handleAvatarUpload = async url => {
    setIsLoading(true);

    const res = await updateDriverAvatarUrl(driver, url);

    if (res) {
      // toast.success(`Successfully updated avatar image!`, { autoClose: 2500 });
    } else {
      toast.error(`Failed to update avatar image!`);
    }

    setIsLoading(false);
  };

  return (
    <>
      <Typography className={cls.headTxt}>Admin Actions</Typography>
      <div className={cls.actions}>
        {/* BACKGROUND CHECK */}

        {/* <Grid container spacing={2} alignItems='center' justifyContent='space-between'>
          <Grid item xs={5}>
            <Typography className={cls.actionTxt}>Background Check</Typography>
          </Grid>

          <Grid item xs>
            {!backgroundCheckStatus || backgroundCheckStatus === `not ready` ? (
              <div className={cls.status}>
                <Icon className={cls.statusIcon} style={{ color: theme.palette.text.disabled }}>
                  radio_button_unchecked
                </Icon>
                <Typography className={cls.statusTxt}>Not Ready</Typography>
              </div>
            ) : null}

            {backgroundCheckStatus === `ready` ? (
              <div className={cls.status}>
                <Icon className={cls.statusIcon} style={{ color: theme.palette.info.main }}>
                  radio_button_unchecked
                </Icon>
                <Typography className={cls.statusTxt}>Ready</Typography>
              </div>
            ) : null}

            {backgroundCheckStatus === `in progress` ? (
              <div className={cls.status}>
                <Icon className={cls.statusIcon} style={{ color: theme.palette.info.main }}>
                  radio_button_checked
                </Icon>
                <Typography className={cls.statusTxt}>In Progress</Typography>
              </div>
            ) : null}
          </Grid>

          <Grid item>
            <Button disabled={backgroundCheckStatus !== `ready`} color='primary' onClick={() => runBackgroundCheck()}>
              Send Profile to Checkr
            </Button>
          </Grid>
        </Grid>

        <Spacer />
        <Divider />
        <Spacer /> */}

        {/* INFORMATIONAL FORMS APPROVAL */}

        <Grid container spacing={2} alignItems='center' justifyContent='space-between'>
          <Grid item xs={5}>
            <Typography className={cls.actionTxt}>Informational Forms Approval</Typography>
          </Grid>

          <Grid item xs>
            {!ifGroup.canApprove() && !ifGroup.isApproved() ? (
              <div className={cls.status}>
                <Icon className={cls.statusIcon} style={{ color: theme.palette.text.disabled }}>
                  radio_button_unchecked
                </Icon>
                <Typography className={cls.statusTxt}>Not Ready</Typography>
              </div>
            ) : null}

            {ifGroup.canApprove() && !ifGroup.isApproved() ? (
              <div className={cls.status}>
                <Icon className={cls.statusIcon} style={{ color: theme.palette.info.main }}>
                  radio_button_unchecked
                </Icon>
                <Typography className={cls.statusTxt}>Ready</Typography>
              </div>
            ) : null}

            {ifGroup.isApproved() ? (
              <div className={cls.status}>
                <Icon className={cls.statusIcon} style={{ color: theme.palette.success.main }}>
                  radio_button_checked
                </Icon>
                <Typography className={cls.statusTxt}>Approved</Typography>
              </div>
            ) : null}
          </Grid>

          <Grid item>
            <Button
              loading={isLoading}
              disabled={!ifGroup.approve || !ifGroup.canApprove() || isLoading}
              color='primary'
              onClick={() => {
                if (ifGroup.approve && ifGroup.canApprove())
                  ifGroup.approve(() => {
                    setIsLoading(false);
                    refetch();
                  });
              }}
            >
              {ifGroup.isApproved() ? <Icon className={cls.btnIcon}>check_circle</Icon> : null}
              {ifGroup.isApproved() ? `Approved` : `Approve`}
            </Button>
          </Grid>
        </Grid>

        <Spacer />
        <Divider />
        <Spacer />

        {/* TRAINING */}

        <Grid container spacing={2} alignItems='center' justifyContent='space-between'>
          <Grid item xs={5}>
            <Typography className={cls.actionTxt}>Training</Typography>
          </Grid>

          <Grid item xs>
            {!trainingStatus || trainingStatus === `not ready` ? (
              <div className={cls.status}>
                <Icon className={cls.statusIcon} style={{ color: theme.palette.text.disabled }}>
                  radio_button_unchecked
                </Icon>
                <Typography className={cls.statusTxt}>Not Ready</Typography>
              </div>
            ) : null}

            {trainingStatus === `ready` ? (
              <div className={cls.status}>
                <Icon className={cls.statusIcon} style={{ color: theme.palette.info.main }}>
                  radio_button_unchecked
                </Icon>
                <Typography className={cls.statusTxt}>Ready</Typography>
              </div>
            ) : null}

            {trainingStatus === `in progress` ? (
              <div className={cls.status}>
                <Icon className={cls.statusIcon} style={{ color: theme.palette.info.main }}>
                  adjust
                </Icon>
                <Typography className={cls.statusTxt}>In Progress</Typography>
              </div>
            ) : null}

            {trainingStatus === `done` ? (
              <div className={cls.status}>
                <Icon className={cls.statusIcon} style={{ color: theme.palette.success.main }}>
                  radio_button_checked
                </Icon>
                <Typography className={cls.statusTxt}>Done</Typography>
              </div>
            ) : null}
          </Grid>

          <Grid item>
            <Button
              loading={isLoading}
              disabled={(trainingStatus !== `ready` && trainingStatus !== `in progress`) || isLoading}
              color='primary'
              onClick={
                trainingStatus === `in progress`
                  ? () => completeTraining(driver, refetch)
                  : () => initiateTraining(driver, refetch)
              }
            >
              {trainingStatus === `done` ? <Icon className={cls.btnIcon}>check_circle</Icon> : null}
              {trainingStatus === `done`
                ? `Complete`
                : trainingStatus === `in progress`
                ? `Mark As Complete`
                : `Start Training`}
            </Button>
          </Grid>
        </Grid>

        <Spacer />
        <Divider />
        <Spacer />

        {/* ASSIGN AVATAR */}

        <Grid container spacing={2} alignItems='center' justifyContent='space-between'>
          <Grid item xs={5}>
            <Typography className={cls.actionTxt}>Upload Avatar Image</Typography>
          </Grid>

          <Grid item xs>
            {!driver.avatar_url ? (
              <div className={cls.status}>
                <Icon className={cls.statusIcon} style={{ color: theme.palette.info.main }}>
                  radio_button_unchecked
                </Icon>
                <Typography className={cls.statusTxt}>Ready</Typography>
              </div>
            ) : null}

            {driver.avatar_url ? (
              <div className={cls.status}>
                <Icon className={cls.statusIcon} style={{ color: theme.palette.success.main }}>
                  radio_button_checked
                </Icon>
                <Typography className={cls.statusTxt}>Done</Typography>
              </div>
            ) : null}
          </Grid>

          <Grid item>
            <DriverImageDropzone
              driver={driver}
              defaultImageUrl={driver?.avatar_url}
              onImageUrlChange={handleAvatarUpload}
            />
          </Grid>
        </Grid>
      </div>
    </>
  );
}

//////////////////////// STYLES ////////////////////////

const useStyles = makeStyles(theme => ({
  headTxt: {
    fontSize: 18,
    fontWeight: 500,
    color: theme.palette.text.disabled,
  },

  actions: {
    position: 'relative',
    padding: theme.spacing(2),
    border: theme.border[0],
    borderRadius: theme.shape.borderRadius,
  },
  actionTxt: {
    lineHeight: 1.25,
    fontSize: 16,
    fontWeight: 500,
    [theme.breakpoints.down('sm')]: {
      fontSize: 15,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 14,
    },
  },

  status: {
    display: 'flex',
    alignItems: 'center',
  },
  statusIcon: {
    display: 'block',
    marginBottom: 2,
    marginRight: theme.spacing(1),
    fontSize: 14,
    [theme.breakpoints.down('sm')]: {
      fontSize: 13,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 12,
    },
  },
  statusTxt: {
    lineHeight: 1.25,
    fontSize: 14,
    fontWeight: 500,
    color: theme.palette.text.secondary,
    [theme.breakpoints.down('sm')]: {
      fontSize: 13,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 12,
    },
  },

  btnIcon: {
    marginTop: -2,
    marginLeft: -4,
    marginRight: 8,
    fontSize: 16,
  },
}));
