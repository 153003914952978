import React from 'react';
import dayjs from 'dayjs';
import { makeStyles, Grid, Typography, Tooltip } from '@material-ui/core';
import { Spacer } from '@hopdrive/storybook';

import { useTools } from '../../hooks/useTools';

//////////////////////// COMPONENT ////////////////////////

export default function PayoutInfo({ payout = {}, driverPay = {} }) {
  const cls = useStyles();

  const { capEach, getInitialsFromName, formatUSD, getReadableText } = useTools();

  return (
    <Grid container spacing={2} alignItems='center'>
      <Grid item>
        {driverPay.driver_avatar_url ? (
          <div className={cls.avatar}>
            <img className={cls.avatarImg} src={driverPay.driver_avatar_url} alt='avatar' />
          </div>
        ) : (
          <div className={cls.avatar}>
            <div className={cls.avatarImg}>
              <Typography className={cls.initialsTxt}>
                {getInitialsFromName({ driver_name: driverPay.driver_name })}
              </Typography>
            </div>
          </div>
        )}
      </Grid>

      <Grid item xs>
        <div className={cls.paper}>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <Tooltip placement='top-start' title='Payout ID'>
                <Typography className={cls.headTxt} style={{ textAlign: 'left' }}>
                  {payout.id ? `Payout #${payout.id}` : `No Payout ID`}
                </Typography>
              </Tooltip>
            </Grid>
            <Grid item xs={4}>
              <Tooltip placement='top' title='Driver Name & ID'>
                <Typography className={cls.headTxt} style={{ textAlign: 'center' }}>
                  {driverPay.driver_name || `HopDriver`} ({driverPay.driver_id || `-`})
                </Typography>
              </Tooltip>
            </Grid>
            <Grid item xs={4}>
              <Tooltip placement='top-end' title='Payout Status'>
                <Typography className={cls.headTxt} style={{ textAlign: 'right' }}>
                  {capEach(payout.status)}
                </Typography>
              </Tooltip>
            </Grid>
          </Grid>

          <Spacer />

          <Grid container spacing={2}>
            <Grid item md={6} xs={12}>
              <div className={cls.listItem}>
                <Typography className={cls.keyTxt}>Type:&nbsp;&nbsp;&nbsp;</Typography>
                <Typography className={cls.valTxt}>{getReadableText(payout.type) || `-`}</Typography>
              </div>
              <div className={cls.lineBreak} />
              <div className={cls.listItem}>
                <Typography className={cls.keyTxt}>Created&nbsp;At:&nbsp;&nbsp;&nbsp;</Typography>
                <Typography className={cls.valTxt}>
                  {dayjs(payout.created_at).format(`MM/DD/YYYY h:mm A`) || `-`}
                </Typography>
              </div>
              <div className={cls.lineBreak} />
              <div className={cls.listItem}>
                <Typography className={cls.keyTxt}>Payment&nbsp;Range:&nbsp;&nbsp;&nbsp;</Typography>
                <Typography className={cls.valTxt}>
                  {driverPay.start_time && driverPay.end_time
                    ? `${dayjs(driverPay.start_time).format(`MM/DD/YYYY`)}
                    ${` - `}
                    ${dayjs(driverPay.end_time).format(`MM/DD/YYYY`)}`
                    : `-`}
                </Typography>
              </div>
            </Grid>

            <div className={cls.hiddenBreak} />

            <Grid item md={6} xs={12}>
              <div className={cls.listItem}>
                <Typography className={cls.keyTxt}>Amount:&nbsp;&nbsp;&nbsp;</Typography>
                <Typography className={cls.valTxt}>{formatUSD(payout.amount)}</Typography>
              </div>
              <div className={cls.lineBreak} />
              <div className={cls.listItem}>
                <Typography className={cls.keyTxt}>Transfer&nbsp;Token:&nbsp;&nbsp;&nbsp;</Typography>
                <Typography className={cls.valTxt}>{payout.transfer_token || `-`}</Typography>
              </div>
              <div className={cls.lineBreak} />
              <div className={cls.listItem}>
                <Typography className={cls.keyTxt}>Notes:&nbsp;&nbsp;&nbsp;</Typography>
                <Typography className={cls.valTxt}>{payout.notes || `-`}</Typography>
              </div>
            </Grid>
          </Grid>
        </div>
      </Grid>
    </Grid>
  );
}

//////////////////////// STYLES ////////////////////////

const useStyles = makeStyles(theme => ({
  paper: {
    position: 'relative',
    width: '100%',
    padding: theme.spacing(2),
    borderRadius: theme.shape.paperRadius,
    background: theme.palette.background.paper,
    boxShadow: theme.shadow.medium,
  },
  avatar: {
    position: 'relative',
    width: 156,
    height: 156,
  },
  avatarImg: {
    position: 'relative',
    objectFit: 'cover',
    width: 156,
    height: 156,
    borderRadius: '50%',
    background: theme.palette.fade[3],
    boxShadow: theme.shadow.medium,
  },
  initialsTxt: {
    position: 'absolute',
    top: '15%',
    left: 0,
    right: 0,
    color: theme.palette.text.contrast,
    textAlign: 'center',
    fontSize: 24,
    fontWeight: 700,
    cursor: 'default',
    userSelect: 'none',
  },
  headTxt: {
    lineHeight: 1.25,
    fontSize: 21,
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: {
      fontSize: 18,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 16,
    },
    cursor: 'default',
  },
  listItem: {
    display: 'flex',
    flexWrap: 'nowrap',
  },
  keyTxt: {
    color: theme.palette.text.secondary,
    textAlign: 'left',
    lineHeight: 1.25,
    fontSize: 14,
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      fontSize: 13,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 12,
    },
  },
  valTxt: {
    width: '100%',
    textAlign: 'right',
    lineHeight: 1.25,
    fontSize: 14,
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      fontSize: 13,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 12,
    },
  },
  lineBreak: {
    width: '100%',
    height: '1px',
    marginTop: '6px',
    marginBottom: '8px',
    background: '#e0e0e0',
  },
  hiddenBreak: {
    display: 'none',
    width: '100%',
    height: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
  },
}));
