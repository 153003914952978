//////////////////////// DEPENDENCIES ////////////////////////

import React from 'react';
import clsx from 'clsx';
import { makeStyles, Typography } from '@material-ui/core';

import { MoveTileHangtagTooltip } from '../index';

//////////////////////// COMPONENT ////////////////////////

export default function MoveTileHangtag({ hangtag, parent = 'move-tile' }) {
  const cls = useStyles();

  const getHangtagStyle = () => {
    let styles = [cls.hangtag];
    if (parent === `move-tile` || parent === `static-drive-tile-alt`) styles.push(cls.hangtagMoveTile);
    if (parent === `unassigned-move-tile` || parent === `static-drive-tile`)
      styles.push(cls.hangtagUnassignedMoveTile);
    if (hangtag?.status === `assigned` || hangtag?.status === `scanned`) styles.push(cls.hangtagProgress);
    if (hangtag?.status === `completed`) styles.push(cls.hangtagCompleted);
    return clsx(styles);
  };

  const getHangtagTextStyle = () => {
    let styles = [cls.hangtagTxt];
    if (hangtag?.rear_code.length >= 3) styles.push(cls.hangtagTxt3);
    if (hangtag?.rear_code.length === 2) styles.push(cls.hangtagTxt2);
    if (hangtag?.rear_code.length === 1) styles.push(cls.hangtagTxt1);
    return clsx(styles);
  };

  return (
    <MoveTileHangtagTooltip hangtag={hangtag}>
      <div className={getHangtagStyle()}>
        <div className={cls.hangtagBox}>
          <div className={cls.hangtagPunch} />
          <Typography className={getHangtagTextStyle()}>{hangtag?.rear_code}</Typography>
        </div>
      </div>
    </MoveTileHangtagTooltip>
  );
}

//////////////////////// STYLES ////////////////////////

const useStyles = makeStyles(theme => ({
  hangtag: {
    overflow: 'hidden',
    transformOrigin: '50% 20%',
    width: 'fit-content',
    border: `2px solid ${theme.palette.primary.contrastText}`,
    borderRadius: 4,
    boxShadow: '0 0 4px #00000060',
    cursor: 'pointer',
  },
  hangtagProgress: {
    animation: 'none',
  },
  hangtagCompleted: {
    animation: '$wiggle 1.6s ease-in-out infinite',
  },
  hangtagMoveTile: {
    zIndex: 276,
    position: 'absolute',
    top: -6,
    right: -8,
  },
  hangtagUnassignedMoveTile: {
    zIndex: 276,
  },

  hangtagBox: {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 24,
    height: 36,
    border: `2px solid ${theme.palette.primary.darker}`,
    borderRadius: 2,
    background: theme.palette.primary.main,
    cursor: 'pointer',
  },

  hangtagPunch: {
    position: 'absolute',
    top: '20%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 5,
    height: 5,
    borderRadius: 5,
    background: theme.palette.primary.darker,
  },

  hangtagTxt: {
    marginTop: 3,
    fontSize: 10,
    fontWeight: 900,
    color: theme.palette.text.contrast,
  },
  hangtagTxt1: {
    fontSize: 12,
  },
  hangtagTxt2: {
    fontSize: 11,
  },
  hangtagTxt3: {
    fontSize: 10,
  },

  '@keyframes wiggle': {
    '0%': {
      transform: 'rotate(0deg)',
    },
    '5%': {
      transform: 'rotate(0deg)',
    },
    '20%': {
      transform: 'rotate(4deg)',
    },
    '30%': {
      transform: 'rotate(-8deg)',
    },
    '40%': {
      transform: 'rotate(12deg)',
    },
    '50%': {
      transform: 'rotate(-16deg)',
    },
    '60%': {
      transform: 'rotate(12deg)',
    },
    '70%': {
      transform: 'rotate(-8deg)',
    },
    '80%': {
      transform: 'rotate(4deg)',
    },
    '95%': {
      transform: 'rotate(0deg)',
    },
    '100%': {
      transform: 'rotate(0deg)',
    },
  },
}));
