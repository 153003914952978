import { createTheme } from '@material-ui/core/styles';

const theme = createTheme({
  palette: {
    default: {
      main: `#323640`,
      light: `#646c80`,
      lighter: `#a0a8b4`,
      dark: `#16181c`,
      contrastText: `#ffffff`,
    },

    primary: {
      main: `#f44232`,
      light: `#f88876`,
      lighter: `#ffdad8`,
      dark: `#b43220`,
      darker: `#a02416`,
      contrastText: `#ffffff`,
    },
    secondary: {
      main: `#486496`,
      light: `#7696c0`,
      lighter: `#dae8ff`,
      dark: `#2b3d59`,
      contrastText: `#ffffff`,
    },
    tertiary: {
      main: `#509090`,
      light: `#88b4b4`,
      dark: `#406464`,
      contrastText: `#ffffff`,
    },
    quaternary: {
      main: `#ffc050`,
      light: `#ffda99`,
      dark: `#e8a020`,
      contrastText: `#ffffff`,
    },

    info: {
      main: `#2080ff`,
      light: `#64b5f6`,
      dark: `#1976d2`,
      contrastText: `#ffffff`,
    },
    error: {
      main: `#ff2050`,
      light: `#ffa0a8`,
      dark: `#d41025`,
      contrastText: `#ffffff`,
    },
    warning: {
      main: `#ffa040`,
      light: `#ffb74d`,
      dark: `#f57c00`,
      contrastText: `#ffffff`,
    },
    success: {
      main: `#20c820`,
      light: `#81c784`,
      dark: `#388e3c`,
      contrastText: `#ffffff`,
    },

    nav: {
      prod: `#323640`,
      test: `#727680`,
    },

    ops: {
      main: `#f05020`,
    },
    concierge: {
      main: `#4080c8`,
      loaner: `#72b0e0`,
    },
    lyft: {
      main: `#ea0b8c`,
    },
    uber: {
      main: `#000000`,
    },
    auto: {
      main: `#20c880`,
    },
    android: {
      main: `#00de7a`,
    },
    ios: {
      main: `#b3b3b3`,
    },

    text: {
      primary: `#323640`,
      secondary: `#32364096`,
      disabled: `#32364072`,
      hint: `#32364072`,
      contrast: `#ffffff`,
      contrastFade: `#ffffff96`,
    },

    background: {
      paper: `#ffffff`,
      default: `#fafafa`,
      light: `#f8f8f8`,
      main: `#f4f4f4`,
      dark: `#f0f0f0`,
    },

    action: {
      hover: `#00000010`,
      selected: `#00000020`,
      focus: `#00000030`,
      active: `#00000080`,
      disabled: `#00000060`,
      disabledBackground: `#00000030`,
    },

    divider: `#00000016`,
    dividerStrong: `#e0e0e0`,
    drop: `#32364010`,

    fade: [
      `#00000010`,
      `#00000020`,
      `#00000030`,
      `#00000040`,
      `#00000050`,
      `#00000060`,
      `#00000080`,
      `#000000a0`,
      `#000000b4`,
      `#000000c8`,
    ],
  },

  border: [`1px solid #00000020`, `1px solid #00000040`, `1px solid #00000060`, `1px solid #e0e0e0`],

  shape: {
    borderRadius: `4px`,
    paperRadius: `8px`,
  },

  shadow: {
    none: `none`,
    soft: `0 0 16px #00000016`,
    medium: `0 0 16px #00000024`,
    harsh: `0 0 16px #00000040`,
    nav: `0 0 16px #00000040`,
    sharp: `1px 1px 4px #00000040`,
  },

  typography: { useNextVariants: true },
});

export default theme;
