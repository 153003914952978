import React from 'react';
import { makeStyles } from '@material-ui/core';

import APDetailTable from '../../components/APDetailTable';

//////////////////////// COMPONENT ////////////////////////

export default function PayoutAPPayments({ driverPay = {} }) {
  const cls = useStyles();

  return (
    <div className={cls.paper}>
      <APDetailTable driverPay={driverPay} />
    </div>
  );
}

//////////////////////// STYLES ////////////////////////

const useStyles = makeStyles(theme => ({
  paper: {
    position: 'relative',
    width: '100%',
    padding: theme.spacing(2),
    borderRadius: theme.shape.paperRadius,
    background: theme.palette.background.paper,
    boxShadow: theme.shadow.medium,
  },
}));
