import React from 'react';
import { toast } from 'react-toastify';
import { makeStyles, Container, Grid } from '@material-ui/core';
import { Divide, Loading, Spacer } from '@hopdrive/storybook';

import * as Sentry from '@sentry/react';
import { useQuery } from '@apollo/client';
import { GET_DRIVER_DETAILS } from './gql';

import { useTools } from '../../hooks/useTools';
import { useDrivers } from '../Drivers/useDrivers';

import { DefaultErrorFallback, DefaultEmptyFallback } from '../../components/Fallbacks';
import Toolbar from '../../components/Toolbar';

import { DriverDetailsProvider } from './DriverDetailsProvider';
import { sendPasswordResetEmail } from '../../utils/authHelper';

import {
  DriverToolbar,
  DriverInfo,
  DriverInfoVehicle,
  DriverInfoAttributes,
  DriverPayouts,
  DriverPlate,
  DriverEquipment,
} from './index';
import TerminationModal from './TerminationModal';
import DriverInfoEventLogs from './DriverInfoEventLogs';

const log = false;

//////////////////////// COMPONENT ////////////////////////

export default function DriverDetails(props) {
  const cls = useStyles();

  const driverId = props.match.params.id;

  const { goToOnboarding, spreadDriverObj } = useTools();
  const { sendOnboardingEmail, triggerDriverClaimsRefresh, triggerDriverAuthCreationInFirebase } =
    useDrivers();

  const [terminationModal, setTerminationModal] = React.useState({ open: false, input: null, output: null });
  const handleTerminationModalOpen = (input = null) => {
    setTerminationModal({ open: true, input: input, output: null });
  };
  const handleTerminationModalClose = async (output = null) => {
    setTerminationModal({ open: false, input: null, output: output });
  };

  const { loading, error, data, refetch } = useQuery(GET_DRIVER_DETAILS, {
    variables: { driverId: driverId },
  });

  // Handle refetch callback to pass as props
  const handleRefetch = () => {
    refetch();
  };

  // LOADING STATE //
  if (loading) {
    return (
      <div className={cls.root}>
        <Container maxWidth='lg'>
          <Toolbar back title='Driver Details' />
          <Loading position='fixed' />
        </Container>
      </div>
    );
  }

  // ERROR STATE //
  if (error) {
    console.error(`Error getting driver details:`, error);
    Sentry.captureException(error);
    return (
      <div className={cls.root}>
        <Container maxWidth='lg'>
          <Toolbar back title='Driver Details' refetch={handleRefetch} />
          <Spacer />
          <DefaultErrorFallback message='ERROR GETTING DRIVER DETAILS' />
        </Container>
      </div>
    );
  }

  // EMPTY STATE //
  if (!data || !data.drivers || !data.drivers.length > 0) {
    return (
      <div className={cls.root}>
        <Container maxWidth='lg'>
          <Toolbar back title='Driver Details' refetch={handleRefetch} />
          <Spacer />
          <DefaultEmptyFallback message='NO DRIVER FOUND' />
        </Container>
      </div>
    );
  }

  // DATA STATE //
  const driverDB = data.drivers[0];
  const driver = spreadDriverObj(driverDB);
  log && console.log(`Driver:`, driver);

  const driverActions = [
    {
      label: `Go To Onboarding`,
      handler: () => goToOnboarding(driver.id),
    },
    {
      label: `Resend Onboarding Email`,
      handler: async () => {
        const res = await sendOnboardingEmail(driver.id, driver.display_name, driver.email, driver.verification);
        if (res) toast.success(`Resent email to driver!`);
        else toast.error(`Failed to resend email to driver!`);
      },
    },
    {
      label: `Reset Password`,
      handler: async () => {
        const resetSuccess = await sendPasswordResetEmail('reset password', driver.email, null);
        if (resetSuccess) {
          toast.success(`Password reset email sent successfully`);
        } else toast.error(`Failed to send password reset email`);
      },
    },
    {
      label: `Refresh Auth Claims`,
      handler: async () => {
        const refreshSuccess = await triggerDriverClaimsRefresh(driverDB.user.id);
        if (refreshSuccess) {
          toast.success(`Token refreshed successfully`);
        } else toast.error(`Failed to refresh token`);
      },
    },
    // {
    //   label: `Invite to Driver App 3`,
    //   handler: async () => {
    //     const success = await triggerDriverAuthCreationInFirebase(driverDB.user.id);
    //     if (success) {
    //       toast.success(`Invitation sent for Driver App 3`);
    //     } else toast.error(`Failed to invite the driver to Driver App 3`);
    //   },
    // },
    {
      label: `Terminate Driver`,
      handler: () => handleTerminationModalOpen({ driver, refetch: handleRefetch }),
      disabled: driver.status !== `offline` && driver.status !== `online`,
    },
  ];

  return (
    <DriverDetailsProvider driver={driver} handleRefetch={handleRefetch}>
      <TerminationModal
        open={terminationModal.open}
        input={terminationModal.input}
        onClose={handleTerminationModalClose}
      />

      <div className={cls.root}>
        <Container maxWidth='lg'>
          <Toolbar back title='Driver Details' refetch={handleRefetch} actions={driverActions}>
            <DriverToolbar driver={driver} />
          </Toolbar>

          <Spacer />

          {/* DRIVER BASE INFO */}
          <DriverInfo driver={driver} />

          <Spacer />

          <Grid container spacing={2}>
            <Grid item md={6} xs={12}>
              {/* DRIVER VEHICLE INFO */}
              <DriverInfoVehicle driver={driver} />
            </Grid>

            <Grid item md={6} xs={12}>
              {/* DRIVER ATTRIBUTES */}
              <DriverInfoAttributes driver={driver} />
            </Grid>
          </Grid>

          {/* DRIVER ACTIONS */}
          {/* <Divide spacer tip={`Show all the available actions on this driver.`}>
            Driver Actions
          </Divide>
          <DriverActions driver={driver} /> */}

          {/* RECENT PAYOUTS TABLE */}
          {driver.driverpayouts && driver.driverpayouts.length ? (
            <>
              <Divide spacer tip={`Show all the driver's recent payouts (50 most recent).`}>
                Recent Payouts Table
              </Divide>
              <DriverPayouts driver={driver} />
            </>
          ) : null}

          {/* PLATE DETAILS & ASSIGNMENT */}
          {driver.plate ? (
            <>
              <Divide spacer tip={`Manage the driver's transporter/driveaway plate.`}>
                Manage Plate
              </Divide>
              <DriverPlate driver={driver} />
            </>
          ) : null}

          {/* EQUIPMENT LIST & ASSIGNMENT */}
          {driver.equipment && driver.equipment.length ? (
            <>
              <Divide spacer tip={`Manage the driver's equipment.`}>
                Manage Equipment
              </Divide>
              <DriverEquipment driver={driver} />
            </>
          ) : null}

          <Spacer />

          {/* DRIVER BASE INFO */}
          <DriverInfoEventLogs driver={driver} />
        </Container>
      </div>
    </DriverDetailsProvider>
  );
}

//////////////////////// STYLES ////////////////////////

const useStyles = makeStyles(theme => ({
  root: {
    display: 'block',
    position: 'relative',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(7),
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
    },
    [theme.breakpoints.down('xs')]: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
  },
}));
