//////////////////////// DEPENDENCIES ////////////////////////

import axios from 'axios';
import dayjs from 'dayjs';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getPropValue } from '@hopdrive/sdk/lib/modules/utilities';
import { getUserToken } from '../utils/authHelper';

/** Breakdown a string of text to its simplest form (remove all formatting and spacing) */
const condensedCase = (str = null) => {
  if (str && typeof str === `string`) {
    const newStr = str
      .replace(/[,.?!:;`'"/{}()<>\s_+-]/g, ``)
      .toLowerCase()
      .trim();
    return newStr;
  }
  return str;
};

/** Capitalize the first letter of the first word */
const capFirst = (str = null) => {
  if (str && typeof str === `string`) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }
  return str;
};

/** Capitalize the first letter of each word */
const capEach = (str = null) => {
  if (str && typeof str === `string`) {
    if (!str.includes(` `)) return str.charAt(0).toUpperCase() + str.slice(1);
    else {
      let arr = str.split(` `);
      arr = arr.map(s => s.charAt(0).toUpperCase() + s.slice(1));
      return arr.join(` `);
    }
  }
  return str;
};

/** Capitalize every letter of every word */
const capAll = (str = null) => {
  if (str && typeof str === `string`) {
    return str.toUpperCase();
  }
  return str;
};

/** Round a number with a precision */
const round = (num = null, precision = 0) => {
  if (num) {
    const multiplier = Math.pow(10, precision);
    const output = Math.round(num * multiplier) / multiplier;
    return output;
  }
  return 0;
};

/** Detect if a number is negative */
const checkNeg = num => {
  if (num > 0) return num;
  else return 0;
};

/** Get human readable name from rideshare partner */
const getFormattedRidesharePartner = (str = null) => {
  if (str && typeof str === `string`) {
    const newStr = condensedCase(str);
    if (newStr === `lyft` || newStr === `l`) return `Lyft`;
    if (newStr === `uber` || newStr === `u`) return `Uber`;
    if (newStr === `auto` || newStr === `a`) return `Auto`;
    if (newStr === `hopdrive` || newStr === `h` || newStr === `hd`) return `HopDrive`;
  }
  return null;
};

/** Get formatted combined status string from a drive or ride */
const getFormattedCombinedStatusFromMove = (move = null) => {
  // Default fallback status string when not enough info is provided
  let fallbackStatus = `Unknown`;

  // Check for move
  if (move) {
    // Set local variables
    fallbackStatus = move.move_type === `drive` ? `Not Assigned` : `Not Started`;
    const cancelStatus = move && move.cancel_status ? condensedCase(move.cancel_status) : null;
    const status = move && move.status ? condensedCase(move.status) : null;

    // Check statuses and return a formatted string
    if (cancelStatus) {
      if (cancelStatus === `pending`) return `Pending Cancel`;
      if (cancelStatus === `seen`) return `Cancel Was Seen`;
      if (cancelStatus === `canceled` || cancelStatus === `cancelled`) return `Cancel Before Started`;
      if (cancelStatus === `started`) return `Cancel After Started`;
      if (cancelStatus === `delivered`) return `Cancel After Delivered`;
      if (cancelStatus === `rescheduled`) return `Rescheduled`;
      return capEach(move.cancel_status);
    }
    if (status) {
      if (status === `dispatched`) return `Dispatched`;
      if (status === `pickupstarted`) return `Pickup Started`;
      if (status === `pickuparrived`) return `Pickup Arrived`;
      if (status === `pickupsuccessful`) return `Pickup Successful`;
      if (status === `deliverystarted`) return `Delivery Started`;
      if (status === `deliveryarrived`) return `Delivery Arrived`;
      if (status === `deliverysuccessful`) return `Delivery Successful`;
      if (status === `awaitingresponse`) return `Awaiting Response`;
      if (status === `accepted`) return `Accepted`;
      if (status === `arrived`) return `Arrived`;
      if (status === `pickedup`) return `Picked Up`;
      if (status === `droppedoff`) return `Dropped Off`;
      if (status === `canceled` || status === `cancelled`) return `Canceled`;
      if (status === `failed`) return `Failed`;
      return capEach(move.status);
    }
  }
  return fallbackStatus;
};

/** Get formatted status string from a drive */
const getFormattedStatusFromDrive = (drive = null) => {
  // Default fallback status string when not enough info is provided
  let fallbackStatus = `Unknown`;

  // Check for drive
  if (drive) {
    // Set local variables
    fallbackStatus = `Not Assigned`;
    const status = drive && drive.status ? condensedCase(drive.status) : null;

    // Check statuses and return a formatted string
    if (status) {
      if (status === `dispatched`) return `Dispatched`;
      if (status === `pickupstarted`) return `Pickup Started`;
      if (status === `pickuparrived`) return `Pickup Arrived`;
      if (status === `pickupsuccessful`) return `Pickup Successful`;
      if (status === `deliverystarted`) return `Delivery Started`;
      if (status === `deliveryarrived`) return `Delivery Arrived`;
      if (status === `deliverysuccessful`) return `Delivery Successful`;
      if (status === `canceled` || status === `cancelled`) return `Canceled`;
      if (status === `failed`) return `Failed`;
      return capEach(drive.status);
    }
  }
  return fallbackStatus;
};

/** Get formatted cancel status string from a drive */
const getFormattedCancelStatusFromDrive = (drive = null) => {
  // Default fallback status string when not enough info is provided
  let fallbackStatus = `Unknown`;

  // Check for drive
  if (drive) {
    // Set local variables
    fallbackStatus = `Not Canceled`;
    const cancelStatus = drive && drive.cancel_status ? condensedCase(drive.cancel_status) : null;

    // Check statuses and return a formatted string
    if (cancelStatus) {
      if (cancelStatus === `pending`) return `Pending Cancel`;
      if (cancelStatus === `seen`) return `Cancel Was Seen`;
      if (cancelStatus === `canceled` || cancelStatus === `cancelled`) return `Cancel Before Started`;
      if (cancelStatus === `started`) return `Cancel After Started`;
      if (cancelStatus === `delivered`) return `Cancel After Delivered`;
      if (cancelStatus === `rescheduled`) return `Rescheduled`;
      return capEach(drive.cancel_status);
    }
  }
  return fallbackStatus;
};

/** Get formatted status string from a ride */
const getFormattedStatusFromRide = (ride = null) => {
  // Default fallback status string when not enough info is provided
  let fallbackStatus = `Unknown`;

  // Check for ride
  if (ride) {
    // Set local variables
    fallbackStatus = `Not Called`;
    const status = ride.status ? condensedCase(ride.status) : null;

    // Check statuses and return a formatted string
    if (status) {
      if (status === `awaitingresponse` || status === `awaiting response` || status === `awaiting_response`)
        return `Awaiting Response`;
      if (status === `pending`) return `Pending`;
      if (status === `processing`) return `Processing`;
      if (status === `estimating`) return `Estimating`;
      if (status === `estimated`) return `Estimated`;
      if (status === `accepted`) return `Accepted`;
      if (status === `arrived`) return `Arrived`;
      if (status === `pickedup` || status === `picked up` || status === `picked_up`) return `Picked Up`;
      if (status === `droppedoff` || status === `dropped off` || status === `dropped_off`) return `Dropped Off`;
      if (status === `notchosen` || status === `not chosen` || status === `not_chosen`) return `Not Chosen`;
      if (status === `canceled` || status === `cancelled`) return `Canceled`;
      if (status === `failed`) return `Failed`;
      return capEach(ride.status);
    }
  }
  return fallbackStatus;
};

/** Get formatted status string from a plan */
const getFormattedStatusFromPlan = plan => {
  if (!plan || !plan.moves) return null;

  // Find the first move being worked in the plan
  let currentMove = plan.moves.find(m => checkMoveInProgress(m));
  if (currentMove) return getFormattedCombinedStatusFromMove(currentMove);
  return null;
};

/** Get formatted status string from a driver */
const getFormattedStatusFromDriver = driver => {
  // Default fallback status string when not enough info is provided
  let fallbackStatus = `Unknown`;

  // Check for driver
  if (driver) {
    // Set local variables
    const status = driver && driver.status ? condensedCase(driver.status) : null;

    // Check statuses and return a formatted string
    if (status) {
      if (status === `new`) return `New`;
      if (status === `interviewed`) return `Interviewed`;
      if (status === `onboarding`) return `Onboarding`;
      if (status === `training`) return `Training`;
      if (status === `offline`) return `Offline`;
      if (status === `online`) return `Online`;
      if (status === `rejected`) return `Rejected`;
      if (status === `terminated`) return `Terminated`;
      return capEach(driver.status);
    }
  }
  return fallbackStatus;
};

/** Get formatted status string from a hangtag */
const getFormattedStatusFromHangtag = (hangtag = null) => {
  // Default fallback status string when not enough info is provided
  let fallbackStatus = `Unknown`;

  // Check for hangtag
  if (hangtag) {
    // Set local variables
    fallbackStatus = `Unassigned`;
    const status = hangtag.status ? hangtag.status.toLowerCase() : null;
    const type = hangtag.type ? hangtag.type.toLowerCase() : null;

    // Check statuses and return a formatted string
    if (status && type === `concierge`) {
      if (status === `unassigned`) return `Unassigned`;
      if (status === `assigned`) return `Assigned`;
      if (status === `scanned`) return `Scanned`;
      if (status === `completed`) return `Completed`;
      return capEach(status);
    }
    if (status && type === `yard`) {
      if (status === `unassigned`) return `Unassigned`;
      if (status === `scanned`) return `Started`;
      if (status === `assigned`) return `Assigned`;
      if (status === `completed`) return `Staged`;
      return capEach(status);
    }
  }
  return fallbackStatus;
};

/** Get formatted vehicle string from the move object */
const getFormattedVehicleFromMove = (move = null, includeYear = true, includeColor = true) => {
  // Default fallback vehicle string when not enough info is provided
  const fallbackVehicle = `Vehicle`;

  // Check for move
  if (move) {
    // Set local variables
    const year = move.vehicle_year || move.driver_vehicle_year || null;
    const make = move.vehicle_make || move.driver_vehicle_make || null;
    const model = move.vehicle_model || move.driver_vehicle_model || null;
    const color = move.vehicle_color || move.driver_vehicle_color || null;

    // Set local vehicle string
    let vehicle = ``;

    // Check for make and model
    if (make && model) vehicle = `${make} ${model}`;
    else if (make) vehicle = make;
    else if (model) vehicle = model;
    else vehicle = fallbackVehicle;

    // Include color and year if specified
    if (includeYear && year) vehicle = `${year} ${vehicle}`;
    if (includeColor && color) vehicle = `${vehicle} (${capFirst(color)})`;

    // Return the built vehicle string
    return vehicle;
  }
  return fallbackVehicle;
};

/** Get the address components from a address */
const getAddressComponents = async address => {
  // Check for address
  if (address && typeof address === `string`) {
    try {
      const token = await getUserToken();
      return await axios({
        method: `POST`,
        url: `/.netlify/functions/getAddressComponents`,
        data: {
          address: address,
        },
        headers: {
          authorization: `Bearer ${token}`,
        },
      })
        .then(res => {
          if (res?.status === 200) {
            return res?.data;
          } else return null;
        })
        .catch(err => {
          console.error(`Failed to get address components from address:`, err);
        });
    } catch (err) {
      console.error(`Failed to get address components from address:`, err);
    }
  }

  // Fallback to returning null
  return null;
};

/** Get the timezone string from a location's latitude and longitude */
const getLatLngTimezone = async (lat, lng) => {
  // Check for latitude and longitude
  if (lat && lng && Number(lat) && Number(lng)) {
    try {
      const token = await getUserToken();
      return await axios({
        method: `POST`,
        url: `/.netlify/functions/getTimezone`,
        data: {
          latitude: lat,
          longitude: lng,
        },
        headers: {
          authorization: `Bearer ${token}`,
        },
      })
        .then(res => {
          if (res?.status === 200) {
            return res?.data?.timeZoneId;
          } else return null;
        })
        .catch(err => {
          console.error(`Failed to get timezone from latitude and longitude:`, err);
        });
    } catch (err) {
      console.error(`Failed to get timezone from latitude and longitude:`, err);
    }
  }

  // Fallback to returning null
  return null;
};

/** Check if the move is in-progress */
const checkMoveInProgress = (move, includeCompleted = false) => {
  if (move) {
    const moveType = condensedCase(move.move_type) || ``;
    const status = condensedCase(move.status) || ``;

    if (moveType === `drive`) {
      if (status !== `deliverysuccessful` && (status.includes(`pickup`) || status.includes(`delivery`))) return true;
    }

    if (moveType === `ride`) {
      if (status === `accepted` || status === `arrived` || status === `pickedup`) return true;
    }

    if (includeCompleted) return checkMoveCompleted(move);
  }
  return false;
};

/** Check if the move was completed */
const checkMoveCompleted = move => {
  if (move) {
    const moveType = condensedCase(move.move_type) || ``;
    const status = condensedCase(move.status) || ``;

    if (moveType === `drive` && status === `deliverysuccessful`) return true;
    if (moveType === `ride` && status === `droppedoff`) return true;
  }
  return false;
};

/** Get the region id from a move - From the lane's pickup or delivery */
const getRegionIdFromMove = (move = null) => {
  if (move) {
    const regionId =
      getPropValue(move, `lane.pickup.region_id`) ||
      getPropValue(move, `lane.pickup.region.id`) ||
      getPropValue(move, `lane.delivery.region_id`) ||
      getPropValue(move, `lane.delivery.region.id`);
    return regionId || null;
  }
  return null;
};

/** Get the region id from a move - From the lane's pickup or delivery */
const getRegionNameFromMove = (move = null) => {
  if (move) {
    const regionName = getPropValue(move, `lane.pickup.region.name`) || getPropValue(move, `lane.delivery.region.name`);
    return regionName || null;
  }
  return null;
};

/** Get the latest timestamp from a move -  */
const getLatestTimestampFromMove = (move = null) => {
  if (move) {
    if (move.move_failed) return move.move_failed;
    if (move.delivery_successful) return move.delivery_successful;
    if (move.delivery_arrived) return move.delivery_arrived;
    if (move.delivery_started) return move.delivery_started;
    if (move.pickup_successful) return move.pickup_successful;
    if (move.pickup_arrived) return move.pickup_arrived;
    if (move.pickup_started) return move.pickup_started;
  }
  return null;
};

/** Get driver name from a move/driver/user object */
const getUserName = (obj = null) => {
  if (obj) {
    if (getPropValue(obj, `name`)) return obj.name;
    if (getPropValue(obj, `driver_name`)) return obj.driver_name;
    if (getPropValue(obj, `display_name`)) return obj.display_name;
    if (getPropValue(obj, `driver.name`)) return obj.driver.name;
    if (getPropValue(obj, `driver.driver_name`)) return obj.driver.driver_name;
    if (getPropValue(obj, `driver.display_name`)) return obj.driver.display_name;
    if (getPropValue(obj, `user.name`)) return obj.user.name;
    if (getPropValue(obj, `user_name`)) return obj.user_name;
    if (getPropValue(obj, `user.driver_name`)) return obj.user.driver_name;
    if (getPropValue(obj, `user.display_name`)) return obj.user.display_name;
    if (getPropValue(obj, `driver.user.name`)) return obj.driver.user.name;
    if (getPropValue(obj, `driver.user.driver_name`)) return obj.driver.user.driver_name;
    if (getPropValue(obj, `driver.user.display_name`)) return obj.driver.user.display_name;
  }
  return null;
};

/** Get driver avatar from a move/driver/user object */
const getDriverAvatar = (obj = null) => {
  if (obj) {
    if (getPropValue(obj, `avatar_url`)) return obj.avatar_url;
    if (getPropValue(obj, `driver.avatar_url`)) return obj.driver.avatar_url;
    if (getPropValue(obj, `user.avatar_url`)) return obj.user.avatar_url;
    if (getPropValue(obj, `driver.user.avatar_url`)) return obj.driver.user.avatar_url;
  }
  return null;
};

/** Get the driver's first name from the display_name */
const getFirstNameFromDisplayName = (displayName = null) => {
  if (displayName) {
    const splitArr = displayName.split(` `);
    if (splitArr.length) {
      const firstName = splitArr[0];
      return firstName;
    }
  }
  return null;
};

/** Get the driver's middle name from the display_name */
const getMiddleNameFromDisplayName = (displayName = null) => {
  if (displayName) {
    const splitArr = displayName.split(` `);
    if (splitArr.length > 2) {
      const middleName = splitArr[1];
      return middleName;
    }
  }
  return null;
};

/** Get the driver's last name from the display_name */
const getLastNameFromDisplayName = (displayName = null) => {
  if (displayName) {
    const splitArr = displayName.split(` `);
    if (splitArr.length > 1) {
      const lastName = splitArr[splitArr.length - 1];
      return lastName;
    }
  }
  return null;
};

/** Get driver initials string from an object */
const getInitialsFromName = (obj = null) => {
  // Default fallback initials string when not enough info is provided
  const fallbackInitials = `N/A`;

  // Check for obj
  if (obj) {
    // Set local variables
    const fullName = getUserName(obj) || fallbackInitials;

    // Get/Set initials
    if (fullName !== fallbackInitials) {
      // Split the full name into first, middle and last
      const firstName = getFirstNameFromDisplayName(fullName);
      const middleName = getMiddleNameFromDisplayName(fullName);
      const lastName = getLastNameFromDisplayName(fullName);

      // Assign each initial
      const firstI = firstName ? firstName[0] : ``;
      const middleI = middleName ? middleName[0] : ``;
      const lastI = lastName ? lastName[0] : ``;

      // Return built initials
      const initials = `${firstI}${middleI}${lastI}`.toUpperCase();
      if (initials && initials !== ``) return initials;
    }
  }
  return fallbackInitials;
};

/** Get driver tags from driver attributes object */
const getDriverTagsFromAttributes = (attributes = {}) => {
  // Assign attributes and remove nulls
  let attributesArr =
    Object.keys(attributes).length > 0
      ? Object.keys(attributes).map((key, i) => {
          const value = Object.values(attributes)[i];
          if (value === true) return `${getReadableText(key)}`.toLowerCase();
          return null;
        })
      : [];
  attributesArr = attributesArr.filter(a => a);
  attributesArr = attributesArr.sort();
  return attributesArr;
};

/** Combine the driver object with child objects to make the data easier to use */
const spreadDriverObj = (driver = null) => {
  let spreadDriver = {};
  if (driver) {
    if (driver.user) {
      spreadDriver = {
        ...spreadDriver,
        ...driver.user,
      };
    }

    if (driver.driverdetail) {
      spreadDriver = {
        ...spreadDriver,
        ...driver.driverdetail,
      };
    }

    if (driver.region) {
      if (!spreadDriver.region_id) spreadDriver.region_id = driver.region.id || null;
      if (!spreadDriver.region_name) spreadDriver.region_name = driver.region.name || null;
    }

    spreadDriver = {
      ...spreadDriver,
      ...driver,
    };

    delete spreadDriver.user;
    delete spreadDriver.driverdetail;
    delete spreadDriver.region;

    if (!spreadDriver.config) spreadDriver.config = {};
    if (!spreadDriver.config.attributes) spreadDriver.config.attributes = {};
    if (!spreadDriver.verification) spreadDriver.verification = {};
    if (!spreadDriver.veteran) spreadDriver.veteran = {};

    return spreadDriver;
  }
  return spreadDriver;
};

/** Get formatted activity string from driverlocation object */
const getActivityFromPoint = (point = null) => {
  // Default fallback activity string when not enough info is provided
  const fallbackActivity = `Unknown`;

  // Check for point
  if (point) {
    // Set local activity string
    let activity = fallbackActivity;

    // Check activity string from driverlocation
    if (point.activity_type === `still`) activity = `Still`;
    if (point.activity_type === `on_foot` || point.activity_type === `walking`) activity = `Walking`;
    if (point.activity_type === `in_vehicle` || point.activity_type === `driving`) activity = `Driving`;

    // Return the built activity string
    return activity;
  }
  return fallbackActivity;
};

/** Get formatted activity icon string from driverlocation object */
const getActivityIconFromPoint = (point = null) => {
  // Default fallback activity icon string when not enough info is provided
  const fallbackActivityIcon = `question_mark`;

  // Check for point
  if (point) {
    // Set local activity icon string
    let activityIcon = fallbackActivityIcon;

    // Check activity icon string from driverlocation
    if (point.activity_type === `still`) activityIcon = `pause`;
    if (point.activity_type === `on_foot` || point.activity_type === `walking`) activityIcon = `directions_walk`;
    if (point.activity_type === `in_vehicle` || point.activity_type === `driving`) activityIcon = `drive_eta`;

    // Return the built activity icon string
    return activityIcon;
  }
  return fallbackActivityIcon;
};

/** Get the general pickup or delivery time from the move to display to the user */
const getPickupOrDeliveryTimeFromMove = (type = `pickup`, move = null, format = `M/D/YYYY h:mm A z`) => {
  if (move) {
    if (type === `pickup`) {
      if (move.pickup_arrived) return dayjs(move.pickup_arrived).format(format);
      if (move.pickup_time) return dayjs(move.pickup_time).format(format);
      if (move.ready_by) return dayjs(move.ready_by).format(format);
    }
    if (type === `delivery`) {
      if (move.delivery_successful) return dayjs(move.delivery_successful).format(format);
      if (move.delivery_time) return dayjs(move.delivery_time).format(format);
      if (move.deliver_by) return dayjs(move.deliver_by).format(format);
      if (move.pickup_time) return dayjs(move.pickup_time).add(Number(move.lane.duration_sec), `second`).format(format);
      if (move.ready_by) return dayjs(move.ready_by).add(Number(move.lane.duration_sec), `second`).format(format);
    }
  }
  return null;
};

/** Get formatted type string from the location object */
const getFormattedTypeFromLocation = (location = null) => {
  // Default fallback status string when not enough info is provided
  const fallbackStatus = `-`;

  // Check for location
  if (location) {
    // Set local variables
    const type = condensedCase(location.type) || ``;

    // Check type and return a formatted string
    if (type && type !== ``) {
      if (type === `customer`) return `Standard`;
      else if (type === `consumerbusiness`) return `Consumer Business`;
      else if (type === `consumerresidential`) return `Consumer Residential`;
      else return capEach(location.type);
    }
  }
  return fallbackStatus;
};

/** Get a location from a list by passing in the id and list to look in */
const getLocationByIdFromList = (locationId, locations) => {
  if (locationId) {
    const foundLocation = locations.find(pl => pl.id === locationId);
    if (foundLocation) return foundLocation;
  }
  return null;
};

/** Get the accessorial in pending status by passing in the accessorials array */
const getPendingAccessorial = accs => {
  return accs.find(acc => acc.status === 'pending' && acc.authorization);
};

/** Get preferred name of location */
const getNameFromLocation = (location = null) => {
  const fallbackName = `Unknown Location`;

  if (location) {
    let locationName = fallbackName;

    if (location.name) locationName = location.name;
    // if (location.nickname) locationName = location.nickname;

    return locationName;
  }

  return fallbackName;
};

/** Get drive type from move */
const getDriveTypeFromMove = (move = null) => {
  const fallbackType = `ops`;

  if (move) {
    let driveType = fallbackType;

    if (move.consumer_pickup && move.consumer_type === `customer`) driveType = `concierge`;
    if (move.consumer_type === `loaner`) driveType = `loaner`;

    return driveType;
  }

  return fallbackType;
};

/** Find the duration between two times (in seconds) */
const durationBetween = (timeOne = null, timeTwo = null) => {
  if (timeOne && timeTwo) {
    const formattedTimeOne = dayjs(timeOne).format();
    const formattedTimeTwo = dayjs(timeTwo);
    const durationSec = formattedTimeTwo.diff(formattedTimeOne, `second`);
    return durationSec;
  }
  return 0;
};

/** Get duration (in minutes) from specified lane */
const getDurationInMinutes = (seconds = 0, precision = 0) => {
  const fallbackDuration = `0 min`;

  if (seconds) {
    return `${Number(seconds / 60).toFixed(precision)} min`;
  }

  return fallbackDuration;
};

/** Get the number of minutes between 2 timestamps */
const getMinutesBetween = (startTimestamp = null, endTimestamp = null) => {
  if (startTimestamp && endTimestamp) {
    let start = dayjs(startTimestamp);
    let end = dayjs(endTimestamp);
    if (start && end) {
      let dur = end.diff(start);
      let mins = Math.round(Math.abs(dayjs.duration(dur).asMinutes()));
      return mins;
    }
  }
  return 0;
};

/** Get the last N digits of a string (N defautls to 8) */
const getLastNDigits = (str = null, n = 8) => {
  if (str && typeof str === `string` && str.length > n) return str.slice(-n);
  return str;
};

/** Get a cleansed text string (remove underscores and cap all) */
const getReadableText = (str = null, nbsp = false) => {
  if (str) {
    let newStr = str.split(/[.\s_-]/g);
    newStr = newStr.map(val => capFirst(val).trim());
    return newStr.join(nbsp ? `\xa0` : ` `);
  }
  return str;
};

/** Get snake case from a string (underscore separators + lowercase) */
const getSnakeCase = (str = null, upperCase = false) => {
  if (str) {
    let newStr = str.split(/[,.\s-]/g);
    newStr = newStr.map(val => val.trim());
    newStr = newStr.join(`_`);
    return upperCase ? newStr.toUpperCase() : newStr.toLowerCase();
  }
  return str;
};

/** Get a cleansed monetary value from a number or string number */
const formatUSD = (value = null, options = {}) => {
  const { returnNull = false, removeSign = false, removeCommas = false, removeCents = false } = options;

  // Check for value and return null
  if (!value && returnNull) return null;

  // Remove spaces and commas so the value can be converted to a number
  // Build the cleansed monetary string from the numeric value
  const replacedValue = `${value}`.replace(/[,]/g, ``);
  const numValue = Number(replacedValue) || 0;
  let cleansedValue = '$' + numValue.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, `$1,`);

  // Additional options
  if (removeSign) cleansedValue = cleansedValue.replace(/[$]/g, ``);
  if (removeCommas) cleansedValue = cleansedValue.replace(/[,]/g, ``);
  if (removeCents) cleansedValue = cleansedValue.split(`.`)[0];

  // Return cleansed monetary value
  return cleansedValue;
};

/** Get the SLA deadline on a move from a specified time (in ms) */
const getMoveDeadline = (deadlineTime, refTime = dayjs().format()) => {
  if (deadlineTime) {
    const slaTime = dayjs(deadlineTime);
    const referenceTime = dayjs(refTime);
    const deadlineMs = slaTime.diff(referenceTime);
    return deadlineMs;
  }
  return null;
};

/** Get a formatted countdown from milliseconds */
const getFormattedCountdown = (ms = null, options = {}) => {
  const { format = `dhms`, fallback = `Past Due` } = options;

  if (ms && !isNaN(ms)) {
    let seconds = Math.floor(ms / 1000);
    let minutes = Math.floor(seconds / 60);
    let hours = Math.floor(minutes / 60);
    let days = Math.floor(hours / 24);

    seconds %= 60;
    minutes %= 60;
    hours %= 24;

    let strArray = [];

    if (format?.includes(`d`) && days > 0) strArray.push(`${days}d`);
    if (format?.includes(`h`) && hours > 0) strArray.push(`${hours}h`);
    if (format?.includes(`m`) && minutes > 0) strArray.push(`${minutes}m`);
    if (format?.includes(`s`) && seconds > 0) strArray.push(`${seconds}s`);

    if (strArray?.length) return strArray.join(` `);
    else return fallback;
  }
  return null;
};

/** Get a cleansed phone number from a string */
const getCleansedPhoneNumber = (str = null, removeCountry = true) => {
  if (str) {
    let cleaned = (`` + str).replace(/\D/g, ``);
    let match = cleaned.match(/^([0-9]+|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      let intlCode = match[1] ? `+${match[1]} ` : `+1 `;
      return [removeCountry ? `` : intlCode, `(`, match[2], `)\xa0`, match[3], `-\u2060`, match[4]].join(``);
    }
  }
  return str;
};

/** Get a cleansed phone number flat from a string */
const getCleansedPhoneNumberFlat = (str = null, removeCountry = true) => {
  if (str) {
    let cleaned = (`` + str).replace(/\D/g, ``);
    let match = cleaned.match(/^([0-9]+|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      let intlCode = match[1] ? `+${match[1]}` : `+1`;
      return [removeCountry ? `` : intlCode, match[2], match[3], match[4]].join(``);
    }
  }
  return str;
};

/** Get a cleansed phone type from a string */
const getCleansedPhoneType = (str = null) => {
  if (str) {
    if (str === `android` || str === `droid`) return `Android`;
    if (str === `ios` || str === `iphone` || str === `apple`) return `iOS`;
    if (str === `other`) return `Other`;
  }
  return str;
};

/** Replace spaces with non-breaking spaces */
const getNonBreakingWord = (str = null) => {
  if (str) {
    let newStr = str.replace(/\s/g, '\xa0');
    return newStr;
  }
  return str;
};

/** Parse move workflow data */
const getWorkflowData = (type, workflow_data, format = `csv`, keyValue = false) => {
  const typeStr = type ? `${type}_` : `extra_`;

  if (workflow_data && typeof workflow_data === `object`) {
    const workflowDataKeys = workflow_data ? Object.keys(workflow_data) : [];
    const workflowDataVals = workflowDataKeys.map(key => workflow_data[key]) || [];

    if (keyValue) {
      const workflowData = workflowDataKeys.map((key, i) => {
        let formattedKey = key;
        if (format === `csv`) formattedKey = typeStr + getSnakeCase(key);
        if (format === `move_details`) formattedKey = getReadableText(key, true);
        return { index: i, key: formattedKey, val: workflowDataVals[i] };
      });

      return workflowData || [];
    } else {
      let workflowData = {};
      workflowDataKeys.forEach((key, i) => {
        let formattedKey = key;
        if (format === `csv`) formattedKey = typeStr + getSnakeCase(key);
        if (format === `move_details`) formattedKey = getReadableText(key, true);
        workflowData[formattedKey] = workflowDataVals[i];
      });

      return workflowData || {};
    }
  }
  return null;
};

/** Get the cancel reason from the move */
const getCancelReasonFromMove = (move = null) => {
  const cancelStatus = move && move.cancel_status ? move.cancel_status : null;
  const cancelReason = move && move.cancel_reason ? move.cancel_reason : null;

  if (cancelReason) return `${cancelReason}.`;
  if (cancelStatus === `pending` || cancelStatus === `seen`) return `Canceled by user.`;
  if (cancelStatus === `delivered`) return `Canceled after vehicle was delivered.`;
  return `Cancel reason not provided.`;
};

/** Check a number to see if its negative, if so, clamp it to 0 */
const clampNegNum = (num = null) => {
  if (num && !isNaN(parseFloat(num)) && num > 0) return num;
  return 0;
};

/** Copy a string to the clipboard */
const copyToClipboard = (str = null) => {
  try {
    if (str) {
      navigator.clipboard.writeText(str);
      toast.info(`Copied text "${str}"`, { autoClose: 2500 });
    } else toast.warning(`No text was found to copy!`);
  } catch (err) {
    toast.error(`Failed to copy text!`);
    console.error(`Failed to copy text:`, err);
  }
};

/** Search a location on google maps */
const searchLocationOnGoogleMaps = (str = null) => {
  try {
    if (str) {
      const searchStr = `https://www.google.com/maps/search/?api=1&query=${str}`;
      window.open(searchStr, `_blank`);
    } else {
      toast.warning(`No location was found to search!`);
    }
  } catch (err) {
    toast.error(`Failed to search location!`);
    console.error(`Failed to search location:`, err);
  }
};

/** Search a lane on google maps */
const searchLaneOnGoogleMaps = (pickupStr = null, deliveryStr = null) => {
  try {
    if (pickupStr && deliveryStr) {
      const searchStr = `https://www.google.com/maps/dir/?api=1&origin=${pickupStr}&destination=${deliveryStr}`;
      window.open(searchStr, `_blank`);
    } else {
      toast.warning(`No locations were found to search!`);
    }
  } catch (err) {
    toast.error(`Failed to search lane!`);
    console.error(`Failed to search lane:`, err);
  }
};

/** Prevent circular reference error on object */
const circularJSONStringify = (json, spaces = 2, hiddenProperties = []) => {
  let cache = [];
  let formattedJson = '';
  try {
    formattedJson = JSON.stringify(
      json,
      (key, value) => {
        if (typeof value === 'object' && value !== null) {
          // Duplicate reference found, discard key
          if (cache.includes(value)) return;

          // Store value in our collection
          cache.push(value);
        }
        return hiddenProperties.includes(key) ? 'hidden by circularStringify()' : value;
      },
      spaces
    );
  } catch (error) {
    console.error(`Failed doing JSON.stringify() on circular object: ${error.message}`, json, error);
  } finally {
    cache = null;
    return formattedJson;
  }
};

/** Generate a string array of years */
const genListOfYears = () => {
  const year = dayjs().add(1, 'y').format('YYYY');
  const till = dayjs().subtract(40, 'y').format('YYYY');
  let yearList = [];
  for (var y = year; y >= till; y--) {
    yearList.push(`${y}`);
  }
  return yearList;
};

/** Generate a string array of colors */
const genListOfColors = () => {
  const listOfColors = [
    `Black`,
    `Blue`,
    `Brown`,
    `Burgundy`,
    `Gold`,
    `Gray`,
    `Green`,
    `Orange`,
    `Pink`,
    `Purple`,
    `Red`,
    `Silver`,
    `Tan`,
    `White`,
    `Yellow`,
  ];
  return listOfColors;
};

/** Generate a string array of states and territories */
const genListOfStates = (includeDC = false, includeTerritories = false) => {
  let listOfStates = [
    { initials: `AL`, full: `Alabama` },
    { initials: `AK`, full: `Alaska` },
    { initials: `AZ`, full: `Arizona` },
    { initials: `AR`, full: `Arkansas` },
    { initials: `CA`, full: `California` },
    { initials: `CO`, full: `Colorado` },
    { initials: `CT`, full: `Connecticut` },
    { initials: `DE`, full: `Delaware` },
    { initials: `FL`, full: `Florida` },
    { initials: `GA`, full: `Georgia` },
    { initials: `HI`, full: `Hawaii` },
    { initials: `ID`, full: `Idaho` },
    { initials: `IL`, full: `Illinois` },
    { initials: `IN`, full: `Indiana` },
    { initials: `IA`, full: `Iowa` },
    { initials: `KS`, full: `Kansas` },
    { initials: `KY`, full: `Kentucky` },
    { initials: `LA`, full: `Louisiana` },
    { initials: `ME`, full: `Maine` },
    { initials: `MD`, full: `Maryland` },
    { initials: `MA`, full: `Massachusetts` },
    { initials: `MI`, full: `Michigan` },
    { initials: `MN`, full: `Minnesota` },
    { initials: `MS`, full: `Mississippi` },
    { initials: `MO`, full: `Missouri` },
    { initials: `MT`, full: `Montana` },
    { initials: `NE`, full: `Nebraska` },
    { initials: `NV`, full: `Nevada` },
    { initials: `NH`, full: `New Hampshire` },
    { initials: `NJ`, full: `New Jersey` },
    { initials: `NM`, full: `New Mexico` },
    { initials: `NY`, full: `New York` },
    { initials: `NC`, full: `North Carolina` },
    { initials: `ND`, full: `North Dakota` },
    { initials: `OH`, full: `Ohio` },
    { initials: `OK`, full: `Oklahoma` },
    { initials: `OR`, full: `Oregon` },
    { initials: `PA`, full: `Pennsylvania` },
    { initials: `RI`, full: `Rhode Island` },
    { initials: `SC`, full: `South Carolina` },
    { initials: `SD`, full: `South Dakota` },
    { initials: `TN`, full: `Tennessee` },
    { initials: `TX`, full: `Texas` },
    { initials: `UT`, full: `Utah` },
    { initials: `VT`, full: `Vermont` },
    { initials: `VA`, full: `Virginia` },
    { initials: `WA`, full: `Washington` },
    { initials: `WV`, full: `West Virginia` },
    { initials: `WI`, full: `Wisconsin` },
    { initials: `WY`, full: `Wyoming` },
  ];

  if (includeDC) {
    listOfStates.push({ initials: `DC`, full: `District of Columbia` });
  }

  if (includeTerritories) {
    listOfStates = [
      ...listOfStates,
      { initials: `AS`, full: `American Samoa` },
      { initials: `GU`, full: `Guam` },
      { initials: `MP`, full: `Northern Mariana Islands` },
      { initials: `PR`, full: `Puerto Rico` },
      { initials: `VI`, full: `U.S. Virgin Islands` },
      { initials: `UM`, full: `U.S. Minor Outlying Islands` },
    ];
  }

  return listOfStates;
};

// Format the pickup_time of a move
const formatTime = time => {
  if (time && typeof time === `string`) {
    const newTime = dayjs(time).format(`MM/DD/YYYY hh:mm A z`);
    return newTime;
  }
  return null;
};

// Get the name of a function
const functionName = f => {
  var ret = f.toString();
  ret = ret.substr('function '.length);
  ret = ret.substr(0, ret.indexOf('('));
  return ret;
};

/** Remove the last point of a geofence if the first and last points are identical
 * This is used for consistency's sake
 */
const conformRegionGeofenceForMap = geofence => {
  if (geofence && geofence.length) {
    if (JSON.stringify(geofence[0]) === JSON.stringify(geofence[geofence.length - 1])) geofence.pop();
    return geofence;
  }
  return [];
};

/** Convert the strange way we store a geofence in the database to something more readable */
const convertRegionGeofenceToReadable = region => {
  if (
    region &&
    region.geofence &&
    region.geofence.coordinates &&
    region.geofence.coordinates[0] &&
    region.geofence.coordinates[0].length
  ) {
    // Set the new geofence to something readable by the map
    let newGeofence = region.geofence.coordinates[0].map(coords => ({ lat: coords[1], lng: coords[0] }));

    // If the first and last points are identical, remove the last point
    newGeofence = conformRegionGeofenceForMap(newGeofence);

    // Return the new geofence
    return newGeofence;
  }
  return [];
};

/** Convert the readable geofence to the way we store it in the database */
const convertRegionGeofenceToDB = region => {
  if (getPropValue(region, `points.length`)) {
    // Set the new geofence to the region points
    let newGeofence = region.points;

    // If the first and last points are NOT identical, duplicate the first point
    // This is required for saving the geofence in our DB for some reason
    if (JSON.stringify(newGeofence[0]) !== JSON.stringify(newGeofence[newGeofence.length - 1]))
      newGeofence.push(newGeofence[0]);

    // Build the database geofence object
    newGeofence = {
      type: 'Polygon',
      crs: {
        type: 'name',
        properties: {
          name: 'EPSG:4326',
        },
      },
      coordinates: [newGeofence.map(coords => [coords.lng, coords.lat])],
    };

    // Return the new geofence
    return newGeofence;
  }
  return {};
};

//Copied surge calculation from netlify function (then refactored it a bit)
const calculateRideCostDelta = (surgeCostPercentage, actualRideshareCost, driveLane, rideLane, rideshareRate, sdk) => {
  console.log("calculating ride cost delta");
  let costDelta = 0;

  //First get percent of surge per the rideshare service, then use it to calculate the delta
  const cleansedSurgePercentage = surgeCostPercentage.replace(/\D/g, '');
  const surgePercentage = cleansedSurgePercentage ? cleansedSurgePercentage / 100 : 0;

  //Check if ride has the inverse lane of its drive. This will determine the calculation method
  const rideDriveLocationMismatch =
    driveLane?.destination_location_id !== rideLane?.origin_location_id ||
    driveLane?.origin_location_id !== rideLane?.destination_location_id;

  //Use rates to generate estimated rideshare return cost for the lane, then calculate delta
  //Cached estimated rideshare return cost from lane is used if no rideshare rate is found
  const driveLaneRideCostEstimate = rideshareRate
    ? sdk.rating.thirdParty.getEstimatedReturnRideCost(
        driveLane?.distance_miles,
        driveLane?.average_drive_speed_mph,
        driveLane?.tolls,
        rideshareRate
      )
    : driveLane?.estimated_rideshare_return_cost;
  const rideLaneRideCostEstimate = rideshareRate
    ? sdk.rating.thirdParty.getEstimatedReturnRideCost(
        rideLane?.distance_miles,
        rideLane?.average_drive_speed_mph,
        rideLane?.tolls,
        rideshareRate
      )
    : rideLane?.estimated_rideshare_return_cost;

  //If the lane on the ride is the inverse of the drive, use the surge percentage from the rideshare provider to calculate delta
  //If the lane on the ride is not the inverse of the drive, subtract the actual cost from the estimated cost to calculate delta
  if (rideDriveLocationMismatch) {
    console.log('Parent move lane does not match ride lane: using surge percentage to calculate delta');
    costDelta = Math.round(surgePercentage * driveLaneRideCostEstimate * 100) / 100;
  } else {
    console.log('Parent move lane is inverse of ride lane. Using actual cost to calculate delta');
    costDelta = Math.round((actualRideshareCost - rideLaneRideCostEstimate) * 100) / 100;
  }

  if(costDelta < 0) {
    console.log('Cost delta is negative, setting to 0');
    costDelta = 0;
  }
  return costDelta;
};

export const vanillaFunctions = {
  condensedCase,
  capFirst,
  capEach,
  capAll,
  round,
  checkNeg,
  getFormattedRidesharePartner,
  getFormattedCombinedStatusFromMove,
  getFormattedStatusFromDrive,
  getFormattedCancelStatusFromDrive,
  getFormattedStatusFromRide,
  getFormattedStatusFromPlan,
  getFormattedStatusFromDriver,
  getFormattedStatusFromHangtag,
  getFormattedVehicleFromMove,
  getAddressComponents,
  getLatLngTimezone,
  checkMoveInProgress,
  checkMoveCompleted,
  getRegionIdFromMove,
  getRegionNameFromMove,
  getLatestTimestampFromMove,
  getUserName,
  getDriverAvatar,
  getFirstNameFromDisplayName,
  getMiddleNameFromDisplayName,
  getLastNameFromDisplayName,
  getInitialsFromName,
  getDriverTagsFromAttributes,
  spreadDriverObj,
  getActivityFromPoint,
  getActivityIconFromPoint,
  getPickupOrDeliveryTimeFromMove,
  getFormattedTypeFromLocation,
  getLocationByIdFromList,
  getPendingAccessorial,
  getNameFromLocation,
  getDriveTypeFromMove,
  durationBetween,
  getDurationInMinutes,
  getMinutesBetween,
  getLastNDigits,
  getReadableText,
  getSnakeCase,
  formatUSD,
  getMoveDeadline,
  getFormattedCountdown,
  getCleansedPhoneNumber,
  getCleansedPhoneNumberFlat,
  getCleansedPhoneType,
  getNonBreakingWord,
  getWorkflowData,
  getCancelReasonFromMove,
  clampNegNum,
  copyToClipboard,
  searchLocationOnGoogleMaps,
  searchLaneOnGoogleMaps,
  circularJSONStringify,
  genListOfYears,
  genListOfColors,
  genListOfStates,
  formatTime,
  conformRegionGeofenceForMap,
  convertRegionGeofenceToReadable,
  convertRegionGeofenceToDB,
  functionName,
  calculateRideCostDelta
};

//////////////////////// HOOK ////////////////////////

export function useTools() {
  const history = useHistory();

  // Travel to different pages
  const goToRoute = (route = null) => {
    if (route) history.push(route);
    else toast.error(`No route was specified!`);
  };
  const goToPreviousPage = () => {
    history.goBack();
  };
  const goToNextPage = () => {
    history.goForward();
  };
  const goToMovesIndex = () => {
    history.push(`/moves`);
  };
  const goToLanesIndex = () => {
    history.push(`/lanes`);
  };
  const goToLocationsIndex = () => {
    history.push(`/locations`);
  };
  const goToRegionsIndex = () => {
    history.push(`/regions`);
  };
  const goToPayoutsIndex = () => {
    history.push(`/driver-payouts`);
  };
  const goToMovePlanner = state => {
    history.push(`/moves/add`, state);
  };
  const goToPlansGroup = options => {
    const { date = undefined, driverIds = [], moveIds = [] } = options;
    history.push(`/plans-group`, { date, driverIds, moveIds });
  };
  const goToMoveDetails = (moveId = null, edit = false, newTab = false) => {
    if (!moveId) {
      toast.error(`No move ID was specified!`);
      return;
    }
  
    //If we're opening in a new tab, we can't use use history 
    //to pass the edit mode prop around, so instead we'll use local storage
    const url = `/moves/${moveId}`;
  
    if (edit) {
      localStorage.setItem('edit-mode-move-details', 'true');
    }
  
    if (newTab) {
      window.open(url, '_blank');
    } else {
      history.push({
        pathname: url,
        state: { editMode: edit },
      });
    }
  };
  const goToMapLane = (laneId = null) => {
    if (laneId) history.push(`/map/lanes/${laneId}`);
    else toast.error(`No lane ID was specified!`);
  };
  const goToMapLocation = (locationId = null) => {
    if (locationId) history.push(`/map/locations/${locationId}`);
    else toast.error(`No location ID was specified!`);
  };
  const goToLaneDetails = (laneId = null) => {
    if (laneId) history.push(`/lanes/${laneId}`);
    else toast.error(`No lane ID was specified!`);
  };
  const goToLocationDetails = (locationId = null) => {
    if (locationId) history.push(`/locations/${locationId}`);
    else toast.error(`No location ID was specified!`);
  };
  const goToOrganizationAdd = (state = null) => {
    if (state) history.push({ pathname: `/organizations/add`, state: state });
    else history.push(`/organizations/add`);
  };
  const goToDealerUserAdd = (state = null) => {
    if (state) history.push({ pathname: `/customers/users/add`, state: state });
    else history.push(`/customers/add`);
  };
  const goToDealerUserDetails = (userId = null) => {
    if (userId) history.push(`/customers/users/${userId}`);
    else toast.error(`No user ID was specified!`);
  };
  const goToAdminUserDetails = (userId = null, promptDriver = null) => {
    console.log('userId', userId, 'state', promptDriver);
    if (userId) {
      if (promptDriver) history.push({ pathname: `/users/${userId}`, state: { promptDriver: promptDriver } });
      else history.push(`/users/${userId}`);
    } else toast.error(`No user ID was specified!`);
  };
  const goToOrganizationDetails = (organizationId = null) => {
    if (organizationId) history.push(`/organizations/${organizationId}`);
    else toast.error(`No organization ID was specified!`);
  };
  const goToCustomerAdd = (organizationId = null) => {
    if (organizationId) history.push(`/customers/add/${organizationId}`);
    else history.push(`/customers/add`);
  };
  const goToCustomerDetails = (customerId = null) => {
    if (customerId) history.push(`/customers/${customerId}`);
    else toast.error(`No customer ID was specified!`);
  };
  const goToUserDetails = (userId = null) => {
    if (userId) history.push(`/users/${userId}`);
    else toast.error(`No user ID was specified!`);
  };
  const goToDriverDetails = (driverId = null) => {
    if (driverId) history.push(`/drivers/${driverId}`);
    else toast.error(`No driver ID was specified!`);
  };
  const goToOnboarding = (driverId = null) => {
    if (driverId) history.push(`/onboarding/${driverId}`);
    else toast.error(`No driver ID was specified!`);
  };
  const goToInvoice = (invoiceId = null, customerId = null, foldId = null) => {
    if (invoiceId) localStorage.setItem('invoice-id', invoiceId);
    // if (customerId) localStorage.setItem('invoice-customer-id', customerId);
    if (foldId) localStorage.setItem('invoice-fold-id', foldId);
    history.push(`/invoices`);
  };
  const goToNotificationDetails = (notificationId = null) => {
    if (notificationId) history.push(`/notifications/${notificationId}`);
    else toast.error(`No notification ID was specified!`);
  };
  const goToPromosDetails = (promoId = null) => {
    if (promoId) history.push(`/promos/${promoId}`);
    else toast.error(`No promo ID was specified!`);
  };
  const goToRegionDetails = (regionId = null) => {
    if (regionId) history.push(`/regions/${regionId}`);
    else toast.error(`No region ID was specified!`);
  };
  const goToRuleDetails = (ruleId = null) => {
    if (ruleId) history.push(`/rules/${ruleId}`);
    else toast.error(`No rule ID was specified!`);
  };
  const goToPayoutDetails = (payoutId = null) => {
    if (payoutId) history.push(`/driver-payouts/${payoutId}`);
    else toast.error(`No payout ID was specified!`);
  };
  const goToInsuranceDetails = (insuranceId = null) => {
    if (insuranceId) history.push(`/insurance/${insuranceId}`);
    else toast.error(`No insurance ID was specified!`);
  };
  const replacePath = (pathname = null) => {
    if (pathname) history.replace({ pathname: pathname });
    else history.replace({ pathname: `/` });
  };

  // Return hook logic
  return {
    goToRoute,
    goToPreviousPage,
    goToNextPage,
    goToMovesIndex,
    goToLanesIndex,
    goToLocationsIndex,
    goToRegionsIndex,
    goToPayoutsIndex,
    goToMovePlanner,
    goToPlansGroup,
    goToMoveDetails,
    goToMapLane,
    goToMapLocation,
    goToLaneDetails,
    goToLocationDetails,
    goToOrganizationAdd,
    goToOrganizationDetails,
    goToCustomerAdd,
    goToCustomerDetails,
    goToDriverDetails,
    goToOnboarding,
    goToNotificationDetails,
    goToPromosDetails,
    goToRegionDetails,
    goToRuleDetails,
    goToPayoutDetails,
    goToInvoice,
    goToInsuranceDetails,
    goToUserDetails,
    replacePath,
    goToDealerUserAdd,
    goToDealerUserDetails,
    goToAdminUserDetails,
    ...vanillaFunctions,
  };
}
