//////////////////////// DEPENDENCIES ////////////////////////

import axios from 'axios';
import { useMutation } from '@apollo/client';
import { toast } from 'react-toastify';

import { SET_MOVE_TAGS } from './gql';
import { getUserToken, getUserEmail } from '../utils/authHelper';

//////////////////////// HOOK ////////////////////////

export function useEngine() {
  const [updateMoveTags] = useMutation(SET_MOVE_TAGS);

  /** Rerun the AR Engine by passing in a move id */
  const rerunAR = async (moveId = null, callback = null) => {
    toast.info(`Recalculating Accounts Receivable. Please wait...`);
    if (moveId) {
      try {
        const token = await getUserToken();
        const email = getUserEmail();
        axios({
          method: `POST`,
          url: `/.netlify/functions/rerunARMove`,
          data: {
            id: moveId,
            user: email,
          },
          headers: {
            authorization: `Bearer ${token}`,
          },
        })
          .then(res => {
            console.log(res);
            if (res.status === 200) {
              toast.success(`Successfully reran Accounts Receivable!`);
              if (callback) callback();
              return res.data;
            } else {
              toast.error(`Failed to rerun Accounts Receivable!`);
              console.error(`Failed to rerun Accounts Receivable!`);
            }
          })
          .catch(err => {
            toast.error(`Failed to rerun Accounts Receivable!`);
            console.error(`Failed to rerun Accounts Receivable:`, err);
          });
      } catch (err) {
        toast.error(`Failed to rerun Accounts Receivable!`);
        console.error(`Failed to rerun Accounts Receivable:`, err);
      }
    } else {
      toast.warning(`Move ID is missing! Could not rerun Accounts Receivable.`);
      console.warn(`Move ID is missing! Could not rerun Accounts Receivable.`);
    }
    return null;
  };

  /** Rerun the AP Engine by passing in a move object */
  const rerunAP = async (moveId = null, callback = null) => {
    toast.info(`Recalculating Accounts Payable. Please wait...`);
    if (moveId) {
      try {
        const token = await getUserToken();
        const email = getUserEmail();
        axios({
          method: `POST`,
          url: `/.netlify/functions/rerunAccountsPayable`,
          data: {
            moveId: moveId,
            user: email,
          },
          headers: {
            authorization: `Bearer ${token}`,
          },
        })
          .then(res => {
            if (res.status === 200) {
              toast.success(`Successfully reran Accounts Payable!`);
              if (callback) callback();
              return res.data;
            } else {
              toast.error(`Failed to rerun Accounts Payable!`);
              console.error(`Failed to rerun Accounts Payable!`);
            }
          })
          .catch(err => {
            toast.error(`Failed to rerun Accounts Payable!`);
            console.error(`Failed to rerun Accounts Payable:`, err);
          });
      } catch (err) {
        toast.error(`Failed to rerun Accounts Payable!`);
        console.error(`Failed to rerun Accounts Payable:`, err);
      }
    } else {
      toast.warning(`Move Object is missing! Could not rerun Accounts Payable.`);
      console.warn(`Move Object is missing! Could not rerun Accounts Payable.`);
    }
    return null;
  };

  /** Cancel a driverpayout transfer */
  const cancelDriverpayout = async (driverpayout = null, callback = null) => {
    if (driverpayout) {
      try {
        const token = await getUserToken();
        return await axios({
          method: `POST`,
          url: `/.netlify/functions/handleDriverPayoutCancel`,
          data: {
            driverpayoutId: driverpayout.id,
            transferToken: driverpayout.transfer_token,
            appayments: driverpayout.appayments,
          },
          headers: {
            authorization: `Bearer ${token}`,
          },
        })
          .then(res => {
            if (res && res.status === 200) {
              if (callback) callback();
              return res.data;
            } else {
              console.error(`Failed to cancel driverpayout!`);
              return null;
            }
          })
          .catch(err => {
            console.error(`Failed to cancel driverpayout:`, err);
            return null;
          });
      } catch (err) {
        console.error(`Failed to cancel driverpayout:`, err);
      }
    } else {
      console.warn(`Driverpayout object is missing! Could not cancel transfer.`);
    }
    return null;
  };

  /** Fetch the 3rd party banking balance amount to show on the screen */
  const fetchBalanceAmount = async (callback = null) => {
    try {
      const token = await getUserToken();
      return await axios({
        method: `POST`,
        url: `/.netlify/functions/fetchBalance`,
        data: {},
        headers: {
          authorization: `Bearer ${token}`,
        },
      })
        .then(res => {
          if (res && res.status === 200) {
            if (callback) callback();
            return res.data && res.data.amount;
          } else {
            console.error(`Failed to fetch balance amount!`);
            return null;
          }
        })
        .catch(err => {
          console.error(`Failed to fetch balance amount:`, err);
          return null;
        });
    } catch (err) {
      console.error(`Failed to fetch balance amount:`, err);
      return null;
    }
  };

  /** Update move tag with 'delivery reported' */
  const tagDeliveryReported = async (move = null) => {
    try {
      let tags = move.tags || ``;
      if (tags === ``) tags = `delivery reported`;
      else if (tags === `delivery reported`) tags = ``;
      else if (tags.includes(`delivery reported`)) {
        tags = tags.replace(',delivery reported', '');
        tags = tags.replace('delivery reported,', '');
      } else tags += `,delivery reported`;

      // Do the mutation
      let variables = {
        moveId: move.id || null,
        tags: tags.length > 0 ? tags : null,
      };
      const res = await updateMoveTags({ variables });
      if (res && res.data && res.data.update_moves && res.data.update_moves.affected_rows > 0) {
        toast.success(`Successfully updated move tags!`);
      }
    } catch (err) {
      toast.error(`Failed to update move tags!`);
      console.error(`Failed to update move tags:`, err);
    }
  };

  /** Build Lanes from addresses */
  async function buildLanesFromAddresses(
    customerId,
    pickupAddress,
    pickupName = '',
    deliveryAddress,
    deliveryName = ''
  ) {
    try {
      let res = await axios({
        method: 'POST',
        url: '/.netlify/functions/buildLanes',
        data: {
          type: 'fromAddresses',
          customer_id: customerId,
          pickup: { name: pickupName.trim(), address: pickupAddress },
          delivery: { name: deliveryName.trim(), address: deliveryAddress },
        },
      });
      if (res && res.data && res.data.length > 0) {
        return res.data[0];
      } else {
        console.log('no lane built: ', res);
        toast.error('Error Building Lane');
      }
    } catch (err) {
      console.error(err);
      toast.error('Error Building Lane');
    }
  }

  /** Build Lanes from locations */
  async function buildLanesFromLocations(
    customerId,
    pickupId,
    pickupAddress,
    pickupName = '',
    deliveryId,
    deliveryAddress,
    deliveryName = ''
  ) {
    try {
      let res = await axios({
        method: 'POST',
        url: '/.netlify/functions/buildLanes',
        data: {
          type: 'fromLocations',
          customer_id: customerId,
          pickup: { name: pickupName.trim(), address: pickupAddress, id: pickupId },
          delivery: { name: deliveryName.trim(), address: deliveryAddress, id: deliveryId },
        },
      });
      if (res && res.data && res.data.length > 0) {
        return res.data[0];
      } else {
        console.log('no lane built: ', res);
        toast.error('Error Building Lane');
      }
    } catch (err) {
      console.error(err);
      toast.error('Error Building Lane');
    }
  }
  /** Build Lanes from addresses using buildLanes background function for extra time */
  async function buildBackgroundLanesFromAddresses(
    customerId,
    pickupAddress,
    pickupName = '',
    deliveryAddress,
    deliveryName = ''
  ) {
    try {
      await axios({
        method: 'POST',
        url: '/.netlify/functions/buildLanes-background',
        data: {
          type: 'fromAddresses',
          customer_id: customerId,
          pickup: { name: pickupName.trim(), address: pickupAddress },
          delivery: { name: deliveryName.trim(), address: deliveryAddress },
        },
      });
      // No return value from background function
      return;
    } catch (err) {
      console.error(err);
      toast.error('Error Building Lane');
    }
  }

  /** Build Lanes from locations using buildLanes background function for extra time */
  async function buildBackgroundLanesFromLocations(
    customerId,
    pickupId,
    pickupAddress,
    pickupName = '',
    deliveryId,
    deliveryAddress,
    deliveryName = ''
  ) {
    try {
      await axios({
        method: 'POST',
        url: '/.netlify/functions/buildLanes-background',
        data: {
          type: 'fromLocations',
          customer_id: customerId,
          pickup: { name: pickupName.trim(), address: pickupAddress, id: pickupId },
          delivery: { name: deliveryName.trim(), address: deliveryAddress, id: deliveryId },
        },
      });
      // No return value from background function
      return;
    } catch (err) {
      console.error(err);
      toast.error('Error Building Lane');
    }
  }
  // Return hook logic
  return {
    rerunAR,
    rerunAP,
    cancelDriverpayout,
    fetchBalanceAmount,
    tagDeliveryReported,
    buildLanesFromAddresses,
    buildLanesFromLocations,
    buildBackgroundLanesFromAddresses,
    buildBackgroundLanesFromLocations,
  };
}
