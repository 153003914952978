import React from 'react';
import { toast } from 'react-toastify';
import { useMutation } from '@apollo/client';
import { getPropValue } from '@hopdrive/sdk/lib/modules/utilities';

import { GET_REGIONS, SAVE_DRIVER_DETAILS } from './gql';
import { useData } from '../../providers/DataProvider';

const log = false;

const DriverDetailsContext = React.createContext({});

function DriverDetailsProvider({ children, driver = {}, handleRefetch }) {
  const { apolloClient } = useData();

  // Extract attributes
  const attributes = driver?.config?.attributes || {};

  // Base State
  const [editMode, setEditMode] = React.useState(false);
  const [isSaving, setIsSaving] = React.useState(false);
  const [regions, setRegions] = React.useState([]);

  // Driver Fields
  const [active, setActive] = React.useState();
  const [avatarUrl, setAvatarUrl] = React.useState();
  const [dob, setDob] = React.useState();
  const [driverAppVersion, setDriverAppVersion] = React.useState();
  const [email, setEmail] = React.useState();
  const [gender, setGender] = React.useState();
  const [phone, setPhone] = React.useState();
  const [phoneType, setPhoneType] = React.useState();
  const [regionId, setRegionId] = React.useState();
  const [taxClass, setTaxClass] = React.useState();
  const [vehicleColor, setVehicleColor] = React.useState();
  const [vehicleLicensePlate, setVehicleLicensePlate] = React.useState();
  const [vehicleLicensePlateState, setVehicleLicensePlateState] = React.useState();
  const [vehicleMake, setVehicleMake] = React.useState();
  const [vehicleModel, setVehicleModel] = React.useState();
  const [vehicleVin, setVehicleVin] = React.useState();
  const [vehicleYear, setVehicleYear] = React.useState();

  // Driver Config Attributes
  const [carmaxCertified, setCarmaxCertified] = React.useState();
  const [carofferCertified, setCarofferCertified] = React.useState();
  const [carvanaCertified, setCarvanaCertified] = React.useState();
  const [genesisCertified, setGenesisCertified] = React.useState();
  const [concierge, setConcierge] = React.useState();
  const [dotCertified, setDotCertified] = React.useState();
  const [manual, setManual] = React.useState();
  const [railyard, setRailyard] = React.useState();

  // Set state on driver and editMode change
  React.useEffect(() => {
    if (driver) {
      setActive(driver.active || false);
      setAvatarUrl(driver.avatar_url || null);
      setDob(driver.date_of_birth || null);
      setDriverAppVersion(driver.driver_app_version || null);
      setEmail(driver.email || null);
      setGender(driver.gender || null);
      setPhone(driver.phone || null);
      setPhoneType(driver.phone_type || null);
      setRegionId(driver.region_id || null);
      setTaxClass(driver.tax_class || null);
      setVehicleColor(driver.vehicle_color || null);
      setVehicleLicensePlate(driver.vehicle_license_plate || null);
      setVehicleLicensePlateState(driver.vehicle_license_plate_state || null);
      setVehicleMake(driver.vehicle_make || null);
      setVehicleModel(driver.vehicle_model || null);
      setVehicleVin(driver.vehicle_vin || null);
      setVehicleYear(driver.vehicle_year || null);

      setCarmaxCertified(attributes.carmax_certified || false);
      setCarofferCertified(attributes.caroffer_certified || false);
      setCarvanaCertified(attributes.carvana_certified || false);
      setGenesisCertified(attributes.genesis_certified || false)
      setConcierge(attributes.concierge || false);
      setDotCertified(attributes.dot_certified || false);
      setManual(attributes.manual || false);
      setRailyard(attributes.railyard || false);
    }
  }, [driver, attributes, editMode]);

  // When no regions are found, lookup regions
  const fetchRegions = async () => {
    const res = await apolloClient.query({ query: GET_REGIONS });
    const newRegions = getPropValue(res, `data.regions`);
    if (newRegions && newRegions.length) {
      setRegions(newRegions);
    }
  };
  React.useEffect(() => {
    if (!regions || !regions.length) {
      fetchRegions();
    }
  }, []);

  // Save changes out of edit mode
  const [saveDriver] = useMutation(SAVE_DRIVER_DETAILS);
  const handleSaveChanges = async () => {
    setIsSaving(true);

    try {
      const variables = {
        driverId: driver.id || null,
        userObj: {
          active: active || false,
          avatar_url: avatarUrl || null,
          email: email ? email.trim() : null,
          phone: phone ? phone.trim() : null,
        },
        driverdetailObj: {
          date_of_birth: dob ? dob.trim() : null,
          gender: gender ? gender.trim() : null,
        },
        driverObj: {
          phone_type: phoneType || null,
          region_id: regionId || null,
          tax_class: taxClass || null,
          vehicle_color: vehicleColor ? vehicleColor.trim() : null,
          vehicle_license_plate: vehicleLicensePlate ? vehicleLicensePlate.trim() : null,
          vehicle_license_plate_state: vehicleLicensePlateState ? vehicleLicensePlateState.trim() : null,
          vehicle_make: vehicleMake ? vehicleMake.trim() : null,
          vehicle_model: vehicleModel ? vehicleModel.trim() : null,
          vehicle_vin: vehicleVin ? vehicleVin.trim() : null,
          vehicle_year: vehicleYear ? vehicleYear.trim() : null,
          driver_app_version: driverAppVersion ? driverAppVersion.trim() : null,
          config: {
            ...driver.config,
            attributes: {
              ...attributes,
              carmax_certified: carmaxCertified || false,
              caroffer_certified: carofferCertified || false,
              carvana_certified: carvanaCertified || false,
              genesis_certified: genesisCertified || false,
              concierge: concierge || false,
              dot_certified: dotCertified || false,
              manual: manual || false,
              railyard: railyard || false,
            },
          },
        },
      };

      const res = await saveDriver({ variables });
      if (res && res.data) {
        if (getPropValue(res, `data.update_users.affected_rows`) > 0)
          log && console.log(`Successfully updated user record:`, res.data.update_users);

        if (getPropValue(res, `data.update_driverdetails.affected_rows`) > 0)
          log && console.log(`Successfully updated driver detail record:`, res.data.update_driverdetails);

        if (getPropValue(res, `data.update_drivers.affected_rows`) > 0)
          log && console.log(`Successfully updated driver record:`, res.data.update_drivers);

        if (
          getPropValue(res, `data.update_users.affected_rows`) > 0 &&
          getPropValue(res, `data.update_drivers.affected_rows`) > 0
        ) {
          toast.success(`Successfully updated driver!`);
          setEditMode(false);
          handleRefetch();
        } else {
          toast.error(`Failed to update driver!`);
          console.error(`Failed to update driver!`);
        }
      }
    } catch (err) {
      toast.error(`Failed to update driver!`);
      console.error(`Failed to update driver:`, err);
    }

    setIsSaving(false);
  };

  // Set Context
  const context = {
    // Base State
    editMode,
    setEditMode,
    isSaving,
    setIsSaving,
    regions,
    setRegions,

    // Edit Vars
    active,
    avatarUrl,
    dob,
    driverAppVersion,
    email,
    gender,
    phone,
    phoneType,
    regionId,
    taxClass,
    vehicleColor,
    vehicleLicensePlate,
    vehicleLicensePlateState,
    vehicleMake,
    vehicleModel,
    vehicleVin,
    vehicleYear,

    carmaxCertified,
    carofferCertified,
    carvanaCertified,
    genesisCertified,
    concierge,
    dotCertified,
    manual,
    railyard,

    // Set Edit Vars
    setActive,
    setAvatarUrl,
    setDob,
    setDriverAppVersion,
    setEmail,
    setGender,
    setPhone,
    setPhoneType,
    setRegionId,
    setTaxClass,
    setVehicleColor,
    setVehicleLicensePlate,
    setVehicleLicensePlateState,
    setVehicleMake,
    setVehicleModel,
    setVehicleVin,
    setVehicleYear,

    setCarmaxCertified,
    setCarofferCertified,
    setCarvanaCertified,
    setGenesisCertified,
    setConcierge,
    setDotCertified,
    setManual,
    setRailyard,

    // Handler Functions
    handleSaveChanges,
  };

  return <DriverDetailsContext.Provider value={context}>{children}</DriverDetailsContext.Provider>;
}

const useDriverDetails = () => React.useContext(DriverDetailsContext);

export { useDriverDetails, DriverDetailsProvider };
